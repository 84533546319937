import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { TeamWeek } from './TeamWeek';
import { WeekLabel } from './WeekLabel';
import Hidden from '@material-ui/core/Hidden';
import { Waypoint } from 'react-waypoint';
import Moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 16px 0 16px',
    minHeight: 'calc(100vh - 136px)',
    boxShadow: '0 0 6px 0 rgba(26, 26, 26, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '8px 8px 0 8px',
    },
  },
  toolbar: {
    height: 56,
    minHeight: 56,
    boxShadow: '0 2px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 4,
        paddingRight: 4,
    },
  },
  title: {
      marginRight: theme.spacing(2),
  },
  displayCurrent: {
      flexGrow: 1,
      textAlign: 'center',
  },
  iconButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          margin: 0,
      },
  },
  weekLabel: {
    height: 28,
    width: '100%', 
  },
  display: {
    minHeight: 'calc(100vh - 220px)',
    width: '100%', 
  },
  loading: {
    position: 'absolute',
    top: '50vh',
    left: 0,
    width: '100vw',
    height: 64,
    margin: 0,
  },
  progress: {
    width: 64,
    display: 'block',
    margin: 'auto',
  },
  teamCalendarLoading: {
    width: 48,
    margin: 'auto',
    padding: 32,

  }
}));


export const TeamCalendarDisplay = (props) => {
  const classes = useStyles();
  

  const previousButton = () => {
    props.setDate(prevDate => {
      let copy = Moment(prevDate);
      return copy.subtract(7, 'days');
    });
  }

  const nextButton = () => {
    props.setDate(nextDate => {
      let copy = Moment(nextDate);
      return copy.add(7, 'days');
    });
  }

  const previousDayButton = () => {
    props.setDate(prevDate => {
      let copy = Moment(prevDate);
      return copy.subtract(1, 'days');
    });
  }

  const nextDayButton = () => {
    props.setDate(nextDate => {
      let copy = Moment(nextDate);
      return copy.add(1, 'days');
    });
  }

  const [firstDayOfWeekView, setFirstDayOfWeekView] = useState(Moment());
  const calendarTitle = firstDayOfWeekView.format('MMMM YYYY')

  useEffect(() => {

    const getFirstDayOfWeek = () => {
      // Get the first sunday of the week
      let firstDay = Moment(props.date).startOf('week');
      if(props.mondayFirst){
        // if today is sunday, need to return previous Monday, else just add 1 day
        if(props.date.day() === 0){
          return firstDay.subtract(6, 'days')
        } else {
          firstDay.add(1, 'days')
        }
      } 
      return firstDay
    }

    let temp = getFirstDayOfWeek()

    if(temp.toString() !== firstDayOfWeekView.toString()){
      setFirstDayOfWeekView(temp);
    }
  }, [firstDayOfWeekView, props.date, props.mondayFirst])

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Hidden smDown>
          <Tooltip title={"Previous Week"} enterDelay={400} >
            <IconButton color="inherit" onClick={previousButton}>
                <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden mdUp>
          <Tooltip title={"Previous Day"} enterDelay={400} >
            <IconButton color="inherit" onClick={previousDayButton}>
                <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Typography variant="h5" className={classes.displayCurrent}>
            {calendarTitle}
        </Typography>
        <Hidden mdUp>
          <Tooltip title={"Next Day"} enterDelay={400} >
            <IconButton color="inherit" className={classes.iconButton} onClick={nextDayButton}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden smDown>
          <Tooltip title={"Next Week"} enterDelay={400} >
            <IconButton color="inherit" className={classes.iconButton} onClick={nextButton}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
      <Grid container spacing={0} className={classes.weekLabel}>
        <WeekLabel 
          mondayFirst={props.mondayFirst} 
          key={'week-label'} 
          team={true}
          date={props.date}
        />
      </Grid>
      {props.data && props.data.getTeamCalendarPagination && props.data.getTeamCalendarPagination.postMap 
        && <Grid 
        container
        direction="row"
        alignItems="stretch" 
        spacing={0} 
        className={classes.display}
        style={{filter: props.data.getTeamCalendarPagination.visibility ? '' : 'blur(3px) brightness(0.8)'}}
      >
        {props.data.getTeamCalendarPagination.postMap.map((userPostMap, index) => {
          return (<React.Fragment key={'week-'+props.date.day()+'-'+userPostMap.user.id}>
            <TeamWeek
              
              todayPost={props.todayPost}
              setTodayPost={props.setTodayPost}
              data={userPostMap} 
              loading={props.loading}
              me={props.me}
              date={props.date}
              firstDay={firstDayOfWeekView} 
              viewMonth={firstDayOfWeekView.month()} 
              weekCount={props.data.getTeamCalendarPagination.postMap.length}
              setOpenModal={props.setOpenModal}
              editPost={props.editPost} 
              setEditPost={props.setEditPost}
              setModalDate={props.setModalDate}
              openModalPost={props.openModalPost}
            />
            { (index === props.data.getTeamCalendarPagination.postMap.length - 4 && props.data.getTeamCalendarPagination.hasMore ) && (
              <Waypoint onEnter={props.fetchMoreTeamCalendar}/>
            )}
          </React.Fragment>
          );
        })}
        {(props.teamCalendarNetworkStatus === 3) && <div className={classes.teamCalendarLoading}><CircularProgress/></div>}
      </Grid>}
      {props.loading && <div className={classes.loading}>
        <div className={classes.progress}>
          <CircularProgress/>
        </div>
      </div>}

    </Paper>);
}