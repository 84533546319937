import React, { useContext } from 'react';
import { AuthContext } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import { TeamDay } from './TeamDay';
import { ActivityTile } from './ActivityTile';
import moment from 'moment';
import DistanceHelper from '../../utils/DistanceHelper';
import { AllowedActivity } from '../../components/Activity/AllowedActivity';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    week: {
      minHeight: 160
    },
    profilePicture: {
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    cell: {
      height: '100%',
      border: "1px solid #E8E8E8",
      padding: 8,
      paddingBottom: 32,
      backgroundColor: '#FBFBFB'
    },
    username: {
      overflowWrap: 'anywhere',
      fontWeight: 600,
      cursor: 'pointer',
      lineHeight: '24px',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    profilePictureBlock: {
      display: 'inline-block',
      height: 24,
      width: 24,
      marginRight: 8,
    },
    usernameBlock: {
      display: 'inline-block',
      width: 'calc(100% - 32px)',
      verticalAlign: 'top',
    }
}));


export const TeamWeek = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const navigateToUserProfile = () => {
    navigate(`/user/${props.data.user.id}`);
  }

  const getPost = (dayDate) => {
    if(props.data && props.data){
      const postList = props.data.posts
      for(var i = 0; i < postList.length; i++){
        var postDate = moment(postList[i].postDate);

        if(postDate.month() === dayDate.month() && postDate.date() === dayDate.date() && postDate.year() === dayDate.year()){
          return postList[i];
        }
      }
    }
    return null;
  }

  const generateDayComponents = () => {
    // Generate the 7 days in a week using the passed firstDay
    let day = moment(props.firstDay);
    let dayComponents = []
    var activityTotals = {}

    let singleDay = moment(props.date)

    const me = props.data.user.id === user.id

    // for mobile view only show one day
    dayComponents.push(
      <Hidden mdUp>
        <TeamDay 
          
          todayPost={props.todayPost}
          setTodayPost={props.setTodayPost}
          post={getPost(singleDay)}
          editPost={props.editPost} 
          setEditPost={props.setEditPost}
          dayDate={singleDay} 
          me={me}
          viewMonth={props.date.month()}
          setOpenModal={props.setOpenModal}
          setModalDate={props.setModalDate}
          openModalPost={props.openModalPost}
        />
      </Hidden>
    )

    for (let i = 0; i < 7; i++) {
      // find post
      var post = getPost(day)

      // update activityTotals
      activityTotals = updateActivityTotals(activityTotals, post)

      dayComponents.push(
        <Hidden smDown>
          <TeamDay 
            post={post}
            todayPost={props.todayPost}
            setTodayPost={props.setTodayPost}
            editPost={props.editPost} 
            setEditPost={props.setEditPost}
            dayDate={day} 
            me={me}
            viewMonth={props.viewMonth}
            setOpenModal={props.setOpenModal}
            setModalDate={props.setModalDate}
            openModalPost={props.openModalPost}
            
          />
        </Hidden>
        )
        day = moment(day).add(1, 'days');
    }
    let totalComponents = []
    totalComponents = Object.entries(activityTotals).map(entry => {
      return (
        <ActivityTile activity={entry[1]} key={'user-total-'+entry[0]+props.firstDay.format('YYYY-DD-MM')} primary={true}/>
      );
    })

    return [(<Grid item xs={4} sm={4} md key={'total-week-'+day.format('YYYY-MM-DD')}>
        <Box className={classes.cell}>
          <span className={classes.profilePictureBlock}>
            <Avatar alt="Profile Picture" className={classes.profilePicture} src={props.data.user.profilePictureURL} onClick={navigateToUserProfile}/>
          </span>
          <span className={classes.usernameBlock}>
            <Typography variant={'body2'} className={classes.username} onClick={navigateToUserProfile}>{props.data.user.username}</Typography>
          </span>
          <Hidden mdDown>
            {totalComponents}
          </Hidden>
        </Box>
      </Grid>), ...dayComponents];
  }

  const updateActivityTotals = (activityTotals, post) => {
    // update totals with post data
    if(post && post.activityList){
      post.activityList.map(activity => {
        // check if already a key in totals
        if(activityTotals[activity.type]){
          activityTotals[activity.type] = {
            'type': activity.type,
            'total': {
              'distance': {
                'value': DistanceHelper.convertDistanceToUnit(activity.distance, activityTotals[activity.type].total.distance.unit) + activityTotals[activity.type].total.distance.value,
                'unit': activityTotals[activity.type].total.distance.unit
              },
              'duration': activity.duration + activityTotals[activity.type].total.duration
            }
          }
        } else {
          // get the selected activity default unit
          var selectedActivity = AllowedActivity[0]
          for(var i=0; i<AllowedActivity.length; i++) {
            if(AllowedActivity[i].type.replace(/\s+/g, '_').toUpperCase() === activity.type){
              selectedActivity = AllowedActivity[i]
              break
            }
          }

          activityTotals[activity.type] = {
            'type': activity.type,
            'total': {
              'distance': {
                'value': DistanceHelper.convertDistanceToUnit(activity.distance, selectedActivity.defaultUnit),
                'unit': selectedActivity.defaultUnit
              },
              'duration': activity.duration
            }
          }
        }
        return null
      })
    }
    return activityTotals
  }

  return (
    <Grid 
      container 
      item 
      spacing={0} 
      direction="row"
      alignItems="stretch"
      className={classes.week} 
    >
      {generateDayComponents()}
    </Grid>
  );
}