import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth';
import { useMutation, gql } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { useLocation, useNavigate } from 'react-router-dom';

// import { Footer } from '../../components/Footer/Footer';

export const ForgotPassword = props => {

  const navigate = useNavigate();
  const location = useLocation();

  var _isMounted = true
  const tagline = "Welcome to Curro, a collaborative running log"

  const [imagePos, setImagePos] = useState(-1)

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const [values, setValues] = useState({
    email: (location.state && location.state.email) ? location.state.email : '',
    emailError: false,
    emailErrorMessage: '',
    errorMessage: '',
    buttonClicked: false,
    transformVal: '50'
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { user } = useContext(AuthContext)

  const SENDFORGOTPASSWORD_USER_MUTATION = gql`
    mutation sendForgotPassword($input: String!) {
      sendForgotPassword(email: $input){
        message
        success
      }
    }
  `;

  const [sendForgotPasswordMutation, {loading}] = useMutation(SENDFORGOTPASSWORD_USER_MUTATION, {
    update(_, {data: {sendForgotPassword: userData}}) {
      _isMounted = false
      if(userData.success){
        setValues({ ...values, errorMessage: '', buttonClicked: true, transformVal: '100'});
      }

    },
    onError(error) {
      if(_isMounted){
        setValues({ ...values, errorMessage: error.message });
      }
    }
  })

  const imageList = ['DSC_0811.jpg', 'DSC_1021.jpg', 'DSC_5789.jpg', 'DSC_8474.jpg', 'DSC_9056.jpg', 'IWU-44.jpg', 'MiniMeet2017-58.jpg', '_DSC3252.jpg', '_DSC5131.jpg']

  useEffect(() => {
    if(user){
      navigate("/")
    }
    if(imagePos === -1){
      setImagePos(Math.floor(Math.random()*imageList.length))
      window.scrollTo(0, 0)
    }
  })
  const textColor = '#8AA0BD'
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'block',
      height: 'calc(100vh - 64px)',
    },
    root: {
      margin: 32,
      marginTop: -56,
      transform: 'translate(70%,'+ values.transformVal + '%)',
      padding: '8px 16px 8px 16px',
      alignItems: 'center',
      flexWrap: 'wrap',  
      [theme.breakpoints.down('md')]: {
        transform: 'translate(0%,'+ values.transformVal + '%)',
        margin: '-56px 0 0 0',
        padding: '2px 4px 2px 4px',
      },
      
    },
    cardContent: {
    },
    logo: {
      margin: 'auto'
    },
    welcome: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
      marginTop: '15px'
    },
    enter: {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'Helvetica',
      fontWeight: 500,
      marginTop: '20px'
    },
    content: {
      position: 'absolute',
      display: 'block',
      zIndex: 1110,
      width: '100vw',
    },
    wrapper:{
      height: '100vh',
      width: '100vw',
      zIndex: -10,
      overflow: 'hidden',
      position: 'absolute',
      backgroundColor: '#a9a9a9',
      marginTop: -56,
    },
    image: {
      height: '100%',
      width: '100%',
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: imagePos !== -1 ? 'url(https://currodevimages.s3.amazonaws.com/background-images/'+imageList[imagePos]+')' : '',
      zIndex: -1,
      filter: 'grayscale(55%) brightness(0.5)',
      opacity: 0.9,
      overflow: 'hidden',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      margin: '30px 0 0 0',
      '& label.Mui-focused': {
        color: theme.palette.text.main,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.text.main,
        },
      },
    },
    signUp: {
      margin: '32px auto 0px auto',
      borderRadius: '21px',
      display: 'flex',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      backgroundColor: 'white',
      width: 112,
    },
    alreadyAccount: {
      textAlign: 'center',
      flexGrow: 1,
      margin: '30px 0 0 0',
      color: "grey",
      fontWeight: 600,
    },
    login: {
      color: theme.palette.primary.main,
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    textColor: {
      color: theme.palette.text.main,
    },
    snackBar: {
      position: 'absolute',
      top: 8,
    },
    tagline: {
      width: 600,
      height: 256,
      position: 'absolute',
      transform: 'translate(-60%, 90%)',
    },
    taglineMessage: {
      color: '#ffffff',
      fontWeight: 600,
      lineHeight: '70px',
      fontSize: '60px',
    }
  }));

  const classes = useStyles();

  const validateForm = (callback) => {

    var emailErrorMessage = 'Invalid Email'

    // Standard for validating email addresses
    // https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
    var emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:+)\])$/;
    var emailValid = emailRegex.test(String(values.email).toLowerCase())
  
    setValues({ ...values, emailError: !emailValid, emailErrorMessage: emailErrorMessage});

    if(emailValid) {
      callback()
    } 
  }

  const sendForgotPasswordEmail = (event) => {
    event.preventDefault();
    validateForm(() => {
      const userInput = {
        input: String(values.email).toLowerCase(),
      }
      sendForgotPasswordMutation({variables: userInput})
    })
  };

  const submitForm = (event) => {
    event.preventDefault()
    sendForgotPasswordEmail()
  }

  const accountAlready = () => {
    navigate('/login')
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.image}></div>
        </div>
        <Container maxWidth="sm" className={classes.container}>
          <Hidden mdDown>
            <div className={classes.tagline}>
              <Typography variant="h1" className={classes.taglineMessage}>{tagline}</Typography>
            </div>
          </Hidden>
          <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
              <div>
                {/* {TODO add bigger logo here} */}
                <Avatar alt="Logo" src={process.env.PUBLIC_URL + '/assets/logo/logoPink192.png'} className={classes.logo}/>
                <Typography variant="h4" className={classes.welcome}>Reset Password</Typography>
                <Typography variant="h4" className={classes.enter}>{ values.buttonClicked ? "An email has been sent to your account." : "Enter the email address for the account to receive a link to reset your password."}</Typography>
              </div>
              { values.buttonClicked ? <Typography variant="h4" size={26}/> : <form noValidate autoComplete="off" onSubmit={submitForm}>
                <TextField 
                  id="login-email" 
                  className={classes.textField} 
                  label="Email" 
                  fullWidth
                  size="small" 
                  value={values.email}
                  required
                  helperText={values.emailError ? values.emailErrorMessage : ''}
                  onChange={handleChange('email')}
                  error={values.emailError}
                  variant="outlined" 
                  InputLabelProps={{
                    style: { color: textColor },
                  }}
                />
                
              </form> }
              <Typography variant="subtitle1" className={classes.errorMessage}>{values.errorMessage}</Typography>

              { values.buttonClicked ? <Typography variant="h4" size={26}/> :<Button variant="contained" className={classes.textField} style={{borderRadius:'21px'}} color="primary" fullWidth size="large" disabled={loading || values.email === ''} type="submit" onClick={sendForgotPasswordEmail}>
                {loading ? <CircularProgress color="inherit" size={26}/> : <a style={{textTransform: 'none'}}>Email a reset link</a> } 
              </Button> }
              <div className={classes.alreadyAccount}>
                  <Link
                    component="button"
                    variant="body2"
                    color="textSecondary"
                    onClick={accountAlready}
                  > 
                    <span className={classes.textColor}>Already have an account? </span><span className={classes.login}>Login</span>
                  </Link>  
                </div>
            </CardContent>
          </Card>
        </Container>
      </div>
    </div>);
}