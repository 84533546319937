import React, { useEffect } from 'react';
import './App.css';
import { AuthProvider } from './auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Home } from './pages/Home/Home';
import { UserCalendar } from './pages/Calendar/UserCalendar';
import { TeamCalendar } from './pages/Calendar/TeamCalendar';
import { Feed } from './pages/Feed/Feed';
import { About } from './pages/About/About';
import { Terms } from './pages/About/Terms';
import { Privacy } from './pages/About/Privacy';
import { Explore } from './pages/Explore/Explore';
import { Settings } from './pages/Settings/Settings';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { ProtectedRoute } from './protected.route';
import { Login } from './pages/Login/Login';
import { CreateAccount } from './pages/CreateAccount/CreateAccount'
import Header from './components/Header/Header';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { onError } from "apollo-link-error";
import jwtDecode from 'jwt-decode'
import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';
import { theme } from './theme';
import Footer from './components/Footer/Footer';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import { ResetPassword } from './pages/Login/ResetPassword';

let uri = 'http://localhost:4000/graphql';

const API_BASE = process.env.REACT_APP_API_BASE

if (API_BASE){
  uri = 'https://' + API_BASE + '/graphql';
} 

const httpLink = createUploadLink({
  uri: uri,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ?  token : "",
    }
  }
});

const client = new ApolloClient({
  credentials: 'include',
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: "token",
      isTokenValidOrUndefined: () => {
        const token = localStorage.getItem('token');
        if (!token) {
          return true;
        }

        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {
        let refresh_uri = 'http://localhost:4000/refresh_token';
        if (API_BASE){
          refresh_uri = 'https://' + API_BASE + '/refresh_token';
        } 
        return fetch(refresh_uri, {
          method: "POST",
          credentials: "include"
        });
      },
      handleFetch: accessToken => {
        if(accessToken){
          localStorage.setItem("token", accessToken)
        } else {
          localStorage.removeItem('token')
        }
      },
      handleError: error => {
        console.error(error);
      }
    }),
    onError(({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors);
      console.log(networkError);
      // If user unauthenticated i.e. no JID token, log them out and reload page
      if(graphQLErrors.length > 0 && (graphQLErrors[0].extensions.code === "UNAUTHENTICATED" || graphQLErrors[0].extensions.code === "INTERNAL_SERVER_ERROR")){
        localStorage.removeItem('token')
        window.location.reload()
      }
    }),
    authLink,
    httpLink
  ]),
  cache
});

function App() {

  useEffect(() => {
    let refresh_uri = 'http://localhost:4000/refresh_token';
    if (API_BASE){
      refresh_uri = 'https://' + API_BASE + '/refresh_token';
    }
    fetch(refresh_uri, {
      method: "POST",
      credentials: "include"
    }).then(async response => {
      const response_json = await response.json();
      if(response_json.success && response_json.token){
        localStorage.setItem("token", response_json.token)
      }
    });
  }, []);

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Header/>
            <Routes>
              <Route path='/' element={ 
                <ProtectedRoute>
                  <UserCalendar/>
                </ProtectedRoute>
              }/>
              <Route path='/home' element={<Home/>}/>
              <Route path='/about' element={<About/>}/>
              <Route path='/terms' element={<Terms/>}/>
              <Route path='/privacy' element={<Privacy/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/resetPassword' element={<ForgotPassword/>}/>
              <Route path='/password_reset/reset' element={<ResetPassword/>}/>
              <Route path='/create' element={<CreateAccount/>}/>
              <Route path='/createAccount' element={<CreateAccount/>}/>
              <Route path='/explore' element={ 
                <ProtectedRoute>
                  <Explore/>
                </ProtectedRoute>
              }/>
              <Route path='/search' element={ 
                <ProtectedRoute>
                  <Explore/>
                </ProtectedRoute>
              }/>
              <Route path='/calendar' element={ 
                <ProtectedRoute>
                  <UserCalendar/>
                </ProtectedRoute>
              }/>
              <Route path='/user/:userid' element={ 
                <ProtectedRoute>
                  <UserCalendar/>
                </ProtectedRoute>
              }/>
              <Route path='/team/:teamid' element={ 
                <ProtectedRoute>
                  <TeamCalendar/>
                </ProtectedRoute>
              }/>
              <Route path='/feed' element={ 
                <ProtectedRoute>
                  <Feed/>
                </ProtectedRoute>
              }/>
              <Route path='/newsfeed' element={ 
                <ProtectedRoute>
                  <Feed/>
                </ProtectedRoute>
              }/>
              <Route path='/setting' element={ 
                <ProtectedRoute>
                  <Settings/>
                </ProtectedRoute>
              }/>
              <Route path='/settings' element={ 
                <ProtectedRoute>
                  <Settings/>
                </ProtectedRoute>
              }/>
              <Route path='/error' element={<PageNotFound/>}/>
              <Route path='/404' element={<PageNotFound/>}/>
              <Route path='*' element={<PageNotFound/>}/>
            </Routes>
            <Footer/>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}
export default App;
