import { InMemoryCache } from '@apollo/client';

const newsfeedIdSet = new Set();
const calendarIdSet = new Set();

export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields:{
        equipmentList:{
          merge(exisiting=[], incoming){
            return incoming
          }
        }
      }
    },
    Query: {
      fields: {
        getFeed: {
          keyArgs: false,
          merge(existing = {posts: []}, incoming) {

            const {posts: newPosts} = incoming
            if(!incoming || !newPosts[0]) {
              return {
                ...existing,
                __typename: "PostPaginator",
              }
            }

            const editInPlaceTypes = ["CreatePost", "DeletePost", "UpdatePost"]

            if(editInPlaceTypes.includes(incoming.__typename)){
              return {
                ...incoming,
                __typename: "PostPaginator",
                posts: newPosts
              }
            }

            const {posts: oldPosts} = existing;

            // TODO check all posts not just first
            if (newsfeedIdSet.has(newPosts[0].__ref)) {
              return {
                ...incoming,
                __typename: "PostPaginator",
                posts: [...oldPosts]
              }
            }

            // TODO check all posts not just first
            newsfeedIdSet.add(newPosts[0].__ref);

            return {
              ...incoming,
              __typename: "PostPaginator",
              posts: [...oldPosts, ...newPosts]
            }
          }
        },
        getNotifications: {
          keyArgs: false,
          merge(existing = {notifications: []}, incoming) {

            const {notifications: newNotifications} = incoming

            const {notifications: oldNotifications} = existing
            
            return {
              ...incoming,
              __typename: "NotificationPaginator",
              notifications: [...oldNotifications, ...newNotifications]
            }
          }
        },
        getTeamCalendarPagination: {
          keyArgs: ["teamId"],
          merge(existing = {postMap: []}, incoming) {

            const {postMap: newPostMap} = incoming
            const {postMap: oldPostMap} = existing

            const editInPlaceTypes = ["CreatePost", "DeletePost", "UpdatePost"]

            if(editInPlaceTypes.includes(incoming.__typename)){
              return {
                ...incoming,
                __typename: "TeamCalendarPaginator",
                posts: newPostMap
              }
            }

            if (calendarIdSet.has(newPostMap[0].user.__ref)) {
              return {
                ...incoming,
                __typename: "TeamCalendarPaginator",
                postMap: [...oldPostMap]
              }
            }
            calendarIdSet.add(newPostMap[0].user.__ref);
            return {
              ...incoming,
              __typename: "TeamCalendarPaginator",
              postMap: [...oldPostMap, ...newPostMap]
            }
          }
        },
      }
    }
  }
})