import React, { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { AuthContext } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import { UserTile } from '../Profile/UserTile';


export const RecommendedUsers = props => {
  const useStyles = makeStyles((theme) => ({
    card: {
      margin: 0,
      width: "100%",
    },
    cardContent: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    profileClick: {
      cursor: 'pointer',
      userSelect: 'none',
      "&:hover": {
        textDecoration: 'underline',
        color: theme.palette.secondary.main,
      },
    },
  }));

  const USER_SEARCH_QUERY = gql`
    query searchUser($search: String!){
      searchUser(search: $search){
        id
        first
        last
        profilePictureURL
        username
        bio
      }
    }
  `;

  const { data, loading } = useQuery(USER_SEARCH_QUERY, { variables: { search: 'a' } })

  
  const { user } = useContext(AuthContext)
  
  const classes = useStyles();

  return (
    
      <Card className={classes.card}>
        <CardHeader
          title={'Recommended Users'}
        />
        <CardContent className={classes.cardContent}>
          {loading ? (
            <React.Fragment>
              <Skeleton animation="wave"  width="100%" variant="rect" height={74} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave"  width="100%" variant="rect" height={74} style={{ marginBottom: 8 }} />        
              <Skeleton animation="wave"  width="100%" variant="rect" height={74} style={{ marginBottom: 8 }} />        
            </React.Fragment>
          ) : (
            <List className={classes.results}> 
              {(data && data.searchUser) && 
                data.searchUser.filter((recommendedUser) => {
                  return recommendedUser.id !== user.id
                }).map((recommendedUser) => {
                  return <UserTile key={"recommended-user-tile" + recommendedUser.id} user={recommendedUser}  handleClose={() => {}}/>
                }) 
              }
            </List>
          )}
        </CardContent>
      </Card>
    );
}