import {gql} from "@apollo/client";

export const GET_STATS = gql`
  query GET_STATS($type: AllowedActivity!, $userId: ID) {
  getStats(type: $type, userId: $userId) {
    yearlyDuration
    yearlyDistance {
      unit
      value
    }
    monthlyDuration
    monthlyDistance {
      unit
      value
    }
    weeklyDuration
    weeklyDistance {
      unit
      value
    }
    allTimeDistance {
      unit
      value
    }
    allTimeDuration
  }
}
`;