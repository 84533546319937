import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../auth';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { TeamCard } from './TeamCard';
import { TeamInfo } from './TeamInfo';
import { useNavigate } from 'react-router-dom';


export const About = (props) => {

  const useStyles = makeStyles((theme) => ({
    background: {
      backgroundColor: '#FFFFFF',
    },
    wrapper:{
      width: '100vw',
      height: 512,
      backgroundColor: '#1a1a1a',
      overflow: 'hidden',
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        height: 384,
      },
    },
    image: {
      position: 'relative',
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'url(https://www.ncaa.com/_flysystem/public-s3/styles/stax_large_content_tile/public-s3/tile-images/hero/W1C2019C053.JPG?h=90c7c397&itok=wTDuzmuN)',
      overflow: 'hidden',
      opacity: 0.5,
    },
    headerBox: {
      position: 'relative',
      width: 568,
      height: 512,
      paddingTop: 64,
      paddingBottom: 64,
      marginLeft: '10vw',
      [theme.breakpoints.down('sm')]: {
        height: 384,
        width: '33vw',
        minWidth: 456
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 32,
        width: '100vw',
        minWidth: '100vw',
      },
    },
    createAccountButton: {
      marginTop: 32,
    },
    imageText: {
      color: 'white',
      fontWeight: 600,
      fontSize: '60px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '48px',
      },
    },
    smallImageText: {
      color: 'white',
      fontWeight: 600,
      fontSize: '48px',
      width: '90%',
    },
    aboutSection: {
      width: '60vw',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: 32,
        width: 'auto'
      },
    },
    sectionTitle: {
      color: theme.palette.primary.main,
      marginTop: 64,
      marginBottom: 32,
    },
    name: {
      fontSize: '0.8675rem',
      fontWeight: 600,
      paddingBottom: 8,
    },
    thankYouSection: {
      paddingTop: 32,
      paddingBottom: 128,
    },
  }));

  
  const classes = useStyles();
  const navigate = useNavigate();

  const createAccount = () => {
    navigate('/createAccount')
  }

  const { user } = useContext(AuthContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const tagline = 'Welcome to Curro, a collaborative running log'

  return (
    <div>
      <div className={classes.background}>
        <div className={classes.wrapper}>
          <div className={classes.image}/>
        </div>
        <div className={classes.headerBox}>
          <Hidden smUp><Typography variant="body1" className={classes.smallImageText}>Welcome to Curro</Typography></Hidden>
          <Hidden xsDown><Typography variant="h4" className={classes.imageText}>{tagline}</Typography></Hidden>
          {!user && <Button variant="contained" className={classes.createAccountButton} style={{borderRadius:'32px'}} color="primary" size="large" onClick={createAccount}>
            Create Account
          </Button>}
        </div>
        <div className={classes.aboutSection}>
          <Typography variant="h4" className={classes.sectionTitle}>Sharing Workouts, Made Simple</Typography>
          <Typography variant="body1">
            Curro was created by athletes, for athletes. Our team of software engineers, developers, and creators wanted to establish a space where <em>team</em> and <em>community</em> work in tandem to provide a platform where athletes from all walks of life can unite behind their shared passion for movement. Our first priority is, and always has been, creating a beautifully clean and usable interface that centers athlete experiences in the context of a greater community of individuals working towards shared goals from across the country. 
          </Typography>
          <br/>
          <Typography variant="body1">
            As competitive runners and athletes ourselves, our team founded Curro in 2020. Today, it continues to grow and be maintained by software engineers, creators, artists, and users from across the country, all united in a shared love of the intersection of technology and activity. 
          </Typography>
          <Typography variant="h4" className={classes.sectionTitle}>The Team</Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={6}
          >
            {TeamInfo.map((member) => (
              <TeamCard key={member.id} member={member}/>
            ))}
          </Grid>
          <Typography variant="h4" className={classes.sectionTitle}>Thank You</Typography>
          <Typography variant="body1">
            Special thanks to all of these people who have helped contribute to this project and made it possible to be where it is today: Evan Han, Julio Trujillo, Jacob Quigley and Marco Quaroni.
          </Typography>
          <div className={classes.thankYouSection}>

          </div>
        </div>
      </div>
    </div>);
}