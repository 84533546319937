import { gql } from '@apollo/client';

const COMMENT_FRAGMENT = gql`
  fragment FeedPageComment on Comment {
    id
    note
    author{
      id
      profilePictureURL
      first
      last
      username
    }
    likeList{
      id
    }
    createdAt
  }
`;

const EQUIPMENT_FRAGMENT = gql`
  fragment EquipmentData on Equipment {
    id
    name
    type
    usage{
      value
      unit
    }
    limit {
      value
      unit
    }
    active
    createdAt
  }
`;

export const POST_FRAGMENT = gql`
  fragment FeedPagePost on Post {
    id
    title
    note
    postDate
    author {
      id
      username
      first
      last
      profilePictureURL
    }
    activityList {
      id
      type
      duration
      distance {
        value
        unit
      }
      equipment{
        ...EquipmentData
      }
      additionalInfo{
        averageHeartRate
        elevationGain
        calories
      }
    }
    likeList {
      user{
        id
      }
    }
    commentList {
      ...FeedPageComment
    }
    createdAt
  }
  ${COMMENT_FRAGMENT},
  ${EQUIPMENT_FRAGMENT}
`;

export const GET_POST_QUERY = gql`
  query getPostList($pageSize: Int, $after:String){
    postList(pageSize: $pageSize, after:$after) @connection(key: "feed", filter: ["type"]) {
      cursor
      hasMore
      posts {
        ...FeedPagePost
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const UPDATE_POST_MUTATION = gql`
  mutation updatePost($input: UpdatePostInput!){
    updatePost(input: $input){
      ...FeedPagePost
    }
  }
  ${POST_FRAGMENT}
`;

export const CREATE_POST_MUTATION = gql`
  mutation createPost($input: CreatePostInput!) {
    createPost(input: $input) {
      ...FeedPagePost
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_POST_BY_ID_QUERY = gql`
  query getPost($id: ID!){
    post(id: $id){
      ...FeedPagePost
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_PERSONAL_POST_BY_ID_QUERY = gql`
  query getPost($id: ID!){
    post(id: $id){
      privateNote
      ...FeedPagePost
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_POST_BY_DATE = gql`
  query getPostByDate($date: String) {
    getPostByDate(date: $date){
      exists
      post {
        ...FeedPagePost
      }
    }
  }
  ${POST_FRAGMENT}
`;

const TEAM_FRAGMENT = gql`
  fragment TeamData on Team {
    id
    name
    private
    description
    profilePictureURL
    memberCount
    owner {
      id
    }
  }
`;
export const CREATE_TEAM_MUTATION = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      ...TeamData
    }
  }
  ${TEAM_FRAGMENT}
`;
export const UPDATE_TEAM_MUTATION = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      ...TeamData
    }
  }
  ${TEAM_FRAGMENT}
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation deleteTeam($input: ID!) {
    deleteTeam(teamId: $input) {
      message
      success
    }
  }
`;

export const ADD_ADMIN_MUTATION = gql`
  mutation addAdmin($input: TeamAdminInput) {
    addAdmin(input: $input) {
      message
      success
    }
  }
`;

export const REMOVE_ADMIN_MUTATION = gql`
  mutation removeAdmin($input: TeamAdminInput) {
    removeAdmin(input: $input) {
      message
      success
    }
  }
`;

export const KICK_MEMBER = gql `
  mutation KICK_MEMBER($input: UserMemberInput){
    kickMember(input: $input) {
      message
      success
    }
  } 
`

export const TRANSFER_OWNERSHIP = gql `
  mutation TRANSFER_OWNERSHIP($input: UserMemberInput){
    transferOwnership(input: $input) {
      message
      success
    }
  } 
`

export const TEAM_QUERY = gql`
  query getTeam($id: ID!){
    team(id: $id) {
      ...TeamData
      createdAt
      adminList {
        id
      }
      memberList {
        id
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

const PROFILE_FRAGMENT = gql`
  fragment ProfileData on User {
    id
    email
    first
    last
    username
    profilePictureURL
    birthdate
    bio
    private
    createdAt
    followerList {
      id
    }
    followingList {
      id
    }
    requestedFollowingList {
      id
    }
    equipmentList {
      ...EquipmentData
    }
    teamList {
      ...TeamData
    }
  }
  ${EQUIPMENT_FRAGMENT}
  ${TEAM_FRAGMENT}
`;

export const ME_QUERY = gql`
  query ME {
    me {
      ...ProfileData
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          ...ProfileData
        }
      }
      ${PROFILE_FRAGMENT}
    `;

export const USER_QUERY = gql`
  query getUser($id: ID!){
    user(id: $id){
      ...ProfileData
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const UPDATE_EQUIPMENT_MUTATION = gql`
  mutation updateEquipment($input: UpdateEquipmentInput!) {
    updateEquipment(input: $input) {
      ...EquipmentData
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const CREATE_EQUIPMENT_MUTATION = gql`
  mutation createEquipment($input: CreateEquipmentInput!) {
    createEquipment(input: $input) {
      ...EquipmentData
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationData on Notification {
    id
    sender {
      id
      username
      first
      last
      profilePictureURL
    }
    team {
      id
      name
      profilePictureURL
    }
    message
    responseRequired
    type
    read
    deleted
    post {
      id
      title
    }
    comment {
      id
      note
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($input: UpdateNotificationInput!){
    updateNotification(input: $input){
      ...NotificationData
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const NOTIFICATION_QUERY = gql`
  query MY_NOTIFICATION_LIST {
    me {
      id
      notificationList {
        ...NotificationData
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const FOLLOWER_STATUS_QUERY = gql`
  query getFollowStatus($userId: ID!) {
    getFollowStatus(userId: $userId) {
      pending
      following
      private
    }
  }
`;

export const USER_CALENDAR_QUERY_DEPRECATED = gql`
  query CALENDAR_POSTS($userId: ID, $date: String){
      getProfileCalendar(userId: $userId, date: $date){
          id
          title
          postDate
          activityList {
              id
              type
              duration
              distance {
                  unit
                  value
              },
              equipment{
                ...EquipmentData
              }
              equipment{
                ...EquipmentData
              }
          }
      }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const USER_CALENDAR_QUERY = gql`
  query GET_PROFILE_CALENDA_WITH_BOOLEAN($userId: ID, $date: String) {
    getProfileCalendarWithBoolean(userId: $userId, date: $date) {
      visibility
      posts {
        id
          title
          postDate
          activityList {
              id
              type
              duration
              distance {
                  unit
                  value
              },
              equipment{
                ...EquipmentData
              }
              equipment{
                ...EquipmentData
              }
          }
      }
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const TEAM_CALENDAR_QUERY = gql`
  query TEAM_CALENDAR_POSTS($teamId: ID!, $date: String){
    getTeamCalendar(teamId: $teamId, date: $date){
      user {
        id
        username
        profilePictureURL
      }
      posts {
        id
        title
        note
        id
        postDate
        activityList {
          id
          type
          duration
          distance {
              unit
              value
          },
          equipment{
            ...EquipmentData
          }
        }
        likeList {
          user {
            id
          }
        }
        commentList {
          ...FeedPageComment
        }
      }
    }
  }
  ${EQUIPMENT_FRAGMENT}
  ${COMMENT_FRAGMENT}
`;

export const ME_EQUIPMENT_QUERY = gql`
    query ME_EQUIPMENT {
      me {
        id
        equipmentList{
          id
          name
          type
          usage{
            value
            unit
          }
          limit {
            value
            unit
          }
          active
          createdAt
        }
      }
    }
  `;

export const GET_NOTIFICATIONS_QUERY = gql`
  query GET_NOTIFICATIONS($page: Int, $limit: Int){
    getNotifications(page: $page, limit: $limit) {
      notifications {
       ...NotificationData
      }
      paginator {
        next
        hasNextPage
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_NEWSFEED_QUERY = gql`
  query GET_NEWSFEED($page: Int, $limit: Int) {
    getFeed(page: $page, limit: $limit ) {
      posts {
        ...FeedPagePost
      }
      paginator {
        next
        hasNextPage
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const GET_TEAM_CALENDAR_PAGINATOR_QUERY = gql`
  query GET_TEAM_CALENDAR_PAGINATOR($teamId: ID!, $date: String, $page: Int, $limit: Int) {
    getTeamCalendarPagination(teamId: $teamId, date: $date, page: $page, limit: $limit){
      visibility
      postMap {
        user {
          id
          username
          profilePictureURL
        }
        posts {
          id
          title
          note
          id
          postDate
          activityList {
            id
            type
            duration
            distance {
                unit
                value
            },
            equipment{
              ...EquipmentData
            }
          }
          likeList {
            user{
              id
            }
          }
          commentList {
            ...FeedPageComment
          }
        }
      }
      hasMore
      nextPage
    }
  }
  ${EQUIPMENT_FRAGMENT}
  ${COMMENT_FRAGMENT}
`;

export const GET_RECENT_USER = gql`
  query GET_RECENT_USER {
    getRecentUsers {
      id
      username
      first
      last
      profilePictureURL
    }
  }
`;

export const GET_TOP_POSTS = gql`
  query GET_TOP_POSTS {
    getTopPosts {
      _id
      title
      note
      author {
        _id
        username
        profilePictureURL
      }
      score
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput){
    createReport(input: $input) {
      id
      type
      reporter {
        id
        username
      }
      post {
        id
        title
      }
    }
  }
`;