import React, {useState, useContext} from 'react';
import { AuthContext } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, gql } from '@apollo/client';
import { LikeButton } from '../Post/LikeButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const CalendarComment = props => {

  const useStyles = makeStyles((theme) => ({
    comment: {
      padding: 0,
    },
    profilePicture: {
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    avatar: {
      minWidth: 32,
    },
    likeCommentSection: {
      position: 'absolute',
      bottom: 0,
      right: -16,
    },
    likeCommentText: {
      display: 'block'
    },
    profileClick: {
      cursor: 'pointer',
      overflowWrap: 'break-word',
      userSelect: 'none',
      fontWeight: 600,
      fontSize: '0.875rem',
      "&:hover": {
        textDecoration: 'underline',
        color: theme.palette.secondary.main,
      },
    },
  }));

  const navigate = useNavigate();

  const { user } = useContext(AuthContext)

  // TODO: Update way to find ifLiked
  const didUserLikeComment = (likeList) => {
    if (likeList && likeList.length > 0) {
      for (let i = 0; i < likeList.length; i++) {
        if (likeList[i].id === user.id) {
          return true
        }
      }
    }
    return false;
  }

  const [commentLikeCount, setCommentLikeCount] = useState(props.comment.likeList ? props.comment.likeList.length : 0)
  const [likeComment, setLikeComment] = useState(didUserLikeComment(props.comment.likeList))

  const LIKE_COMMENT_MUTATION = gql`
    mutation likeComment($input: LikeCommentInput) {
      likeComment(input: $input) {
        liked
      }
    }
  `;

  const [likeCommentMutation, { loading: likeLoading }] = useMutation(LIKE_COMMENT_MUTATION, {
    update(_, { data: { likeComment } }) {
      // console.log(likePost.liked)
      // console.log(props.comment.id)
      // TODO update post in cache with added/removed like
    },
    onError(error) {
      console.log(error)
    }
  })

  const handleLike = (event) => {
    setCommentLikeCount(event.target.checked ? commentLikeCount + 1 : commentLikeCount - 1)
    const likeInput = {
      input: {
        commentId: props.comment.id
      }
    }

    console.log(likeInput)
    likeCommentMutation({ variables: likeInput })
    setLikeComment(event.target.checked)
  }

  const formatComment = (createdAt) => {

    var end = Moment()
    var start = Moment.unix(createdAt/1000)
    var timeSinceComment = Moment.duration(end.diff(start))

    if(timeSinceComment.years()){
      return timeSinceComment.years() + ' year' + (timeSinceComment.years() === 1 ? ' ago' : 's ago')
    }
    if(timeSinceComment.months()){
      return timeSinceComment.months() + ' month' + (timeSinceComment.months() === 1 ? ' ago' : 's ago')
    }
    const daySinceComment = timeSinceComment.days()
    if(daySinceComment){
      if(daySinceComment === 1){
        return daySinceComment + ' day ago'
      } else if(daySinceComment < 7){
        return daySinceComment + ' days ago'
      } else {
        const weekSinceComment = Math.ceil(daySinceComment / 7)
        if(weekSinceComment === 1){
          return weekSinceComment + ' week ago'
        } else {
          return weekSinceComment + ' weeks ago'
        }
      }
    }
    if(timeSinceComment.hours()){
      return timeSinceComment.hours() + ' hour' + (timeSinceComment.hours() === 1 ? ' ago' : 's ago')
    }
    if(timeSinceComment.minutes()){
      return timeSinceComment.minutes() + ' min' + (timeSinceComment.minutes() === 1 ? ' ago' : 's ago')
    }
    return 'now'
  }

  const navigateToUserProfile = () => {
    navigate(`/user/${props.comment.author.id}`);
  }
  const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      success
      message
    }
  }
  `;

  const [deleteCommentMutation, { loading: deleteLoading }] = useMutation(DELETE_COMMENT_MUTATION, {
    update(store, { data: { deleteComment } }) {
      if(deleteComment.success){
        const cacheId = store.identify(props.post);
        store.modify({
          id: cacheId,
          fields: {
            commentList: (cachedCommentList, { toReference }) => {
              return cachedCommentList.filter((comment) => {
                return store.identify(props.comment) !== store.identify(comment)
              })             
            },
          }
        });
        if(props.userPostRefetch){
          props.userPostRefetch()
        }
      }
  },
  onError(error) {
    console.log(error)
  }
})

  const deleteComment = () => {
    const deleteInput = {
      input: {
        commentId: props.comment.id
      }
    }
    deleteCommentMutation({variables: deleteInput})
  }

  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start" className={classes.comment}>
      <ListItemAvatar className={classes.avatar}>
        <Avatar alt="Profile Picture" className={classes.profilePicture} src={props.comment.author.profilePictureURL} onClick={navigateToUserProfile}/>
      </ListItemAvatar>
      <ListItemText
        // primary={<span className={classes.profileClick} onClick={navigateToUserProfile}>{props.comment.author.username}</span>}
        secondary={
          <React.Fragment>
            <Typography
              component="p"
              variant="body2"
              color="textPrimary"
            >
              <span className={classes.profileClick} onClick={navigateToUserProfile}>{props.comment.author.username}</span>
              {' ' + props.comment.note}
            </Typography>
            <Typography
              component="p"
              variant="caption"
            >
              {' ' + formatComment(props.comment.createdAt) 
              } <b 
              className={classes.likeCommentText} 
              >
               {commentLikeCount > 0 ? 
                 commentLikeCount + ' like' + (commentLikeCount > 1 ? 's':'')
                 : ''}
              </b>
                <span className={classes.likeCommentSection}><LikeButton fontSize={11} likeCount={commentLikeCount} isLiked={likeComment} handleLike={handleLike} loading={likeLoading} showLikeCount={false}/></span>
             </Typography>
             
             {/* DELETE COMMENT BUTTON, needs styling tho 
             <b className={classes.deleteCommentText} onClick={deleteComment}>
               {props.comment.author.id === user.id && <DeleteOutlineIcon/>}
              </b> */}
          </React.Fragment>
        }
      />
    </ListItem>
  );
}