const DistanceHelper = {
  convertDistanceToUnit: function (distance, unit) {
    if(!unit){
      return distance.value
    }
    switch (distance.unit.toUpperCase()) {
      case "YDS":
        switch(unit.toUpperCase()){
          case "MI":
            return distance.value / 1760
          case "M":
            return distance.value / 1.094
          case "KM":
            return distance.value / 1094
          case "YDS":
          default:
            return distance.value
        }
      case "MI":
        switch(unit.toUpperCase()){
          case "YDS":
            return distance.value * 1760
          case "M":
            return distance.value * 1609.34
          case "KM":
            return distance.value * 1.60934
          case "MI":
          default:
            return distance.value
        }
      case "M":
        switch(unit.toUpperCase()){
          case "YDS":
            return distance.value * 1.094
          case "M":
          case "KM":
            return distance.value / 1000
          case "MI":
            return distance.value / 1609.34
          default:
            return distance.value
        }
      case "KM":
        switch(unit.toUpperCase()){
          case "YDS":
            return distance.value * 1094
          case "MI":
            return distance.value / 1.60934
          case "M":
            return distance.value * 1000
          case "KM":
          default:
            return distance.value
        }
      default:
        return distance.value
    }
  },
  calculateAveragePace: function(distance, duration, activityType) {
    var covertDistance = {
      value: distance.value,
      unit: distance.unit
    }
    switch(activityType.toUpperCase()) {
      case "WALK":
      case "HIKE":
      case "ROLLER_SKATE":
      case "NORDIC_SKI":
      case "BIKE":
        var totalMiles = this.convertDistanceToUnit(covertDistance, "MI")
        var mph = totalMiles/(duration/(60*60*1000))
        return mph.toFixed(1) + ' mph'
      case "ROWING":
      case "SWIM":
        var totalYards = this.convertDistanceToUnit(covertDistance, "YDS")
        duration = (duration/(totalYards/100))
        var min = Math.floor(duration/60000)
        var sec = Math.floor((duration%60000)/1000)
        sec = sec < 10 ? "0"+sec : sec
        return min + ":" + sec + "/100yds"
      default:
        var totalRunMiles = this.convertDistanceToUnit(covertDistance, "MI")
        duration = duration/totalRunMiles
        var minDefault = Math.floor(duration/60000)
        var secDefault = Math.floor((duration%60000)/1000)
        secDefault = secDefault < 10 ? "0"+secDefault : secDefault
        return minDefault+":"+secDefault+" min/mi"
    }
  },
}
export default DistanceHelper;