import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useLazyQuery, NetworkStatus } from '@apollo/client';
import { PostModal } from '../../components/Modal/PostModal';
import { GET_POST_BY_ID_QUERY } from '../../utils/graphql';
import Skeleton from '@material-ui/lab/Skeleton';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
    fontSize: '12px',
    cursor: 'pointer',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
  resultItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 72,
    right: 0,
    maxWidth: 330,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 380
    },
  }, 
  buttonRow: {
    display: 'block',
    width: '100%',
    marginTop: 8,
    marginBottom: -16
    
  },
  blackShoe: {
    marginRight: 12,
    filter: 'brightness(0) saturate(100%)'
  },
  goldShoe: {
    marginRight: 12,
    filter: 'brightness(0) saturate(100%) invert(60%) sepia(57%) saturate(486%) hue-rotate(12deg) brightness(105%) contrast(89%);'
  },
  silverShoe: {
    marginRight: 12,
    filter: 'brightness(0) saturate(100%) invert(80%) sepia(0%) saturate(1320%) hue-rotate(13deg) brightness(90%) contrast(88%);'
  },
  bronzeShoe: {
    marginRight: 12,
    filter: 'brightness(0) saturate(100%) invert(57%) sepia(6%) saturate(2783%) hue-rotate(357deg) brightness(98%) contrast(85%);'
  },
  followButton: {
    paddingBottom: 4
  },
  profileClick: {
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '0.875rem',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
}));



export const TopPostTile = (props) => {

  const navigate = useNavigate();

  const classes = useStyles();

  const SHOE_RANK_CLASSES = [classes.goldShoe, classes.silverShoe, classes.bronzeShoe, classes.blackShoe];
  const DEFAULT_SHOE_RANK_CLASS = classes.blackShoe;

  const navigateToUserProfile = () => {
    navigate(`/user/${props.user._id}`);
    if(props.handleClose){
      props.handleClose();
    }
  }


  var moreDetailPost = {};
  const [viewPost, setViewPost] = useState(false);
  const [modalPost, setModalPost] = useState(null);
  const [isCommenting, setIsCommenting] = useState(false);

  const [getUserPost, { data: userPostData, loading: userPostLoading, refetch: userPostRefetch, networkStatus }] = useLazyQuery(GET_POST_BY_ID_QUERY, {
    onCompleted: (result) => {
        if (networkStatus === NetworkStatus.refetch){
            setModalPost(result.post)
        } else {
            moreDetailPost[result.post.id] = result.post
            setModalPost(result.post)
            setViewPost(true)
        }
        return 
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => console.log(error)
})
  const openModalPost = (post, commentOnPost) => {
    setIsCommenting(commentOnPost)
    // get post data
    getUserPost({
        variables: {id: props.post._id},
    })
    if(userPostData && userPostData.post && !userPostLoading){
        setModalPost(userPostData.post)
        setViewPost(true)
    }
}

  const getShoeRankClass = (index) => {
    if (index >= SHOE_RANK_CLASSES.length || index < 0) {
      return DEFAULT_SHOE_RANK_CLASS;
    }
    return SHOE_RANK_CLASSES[index];
  }

  return (
    <div>
    <ListItem alignItems="flex-start" className={classes.resultItem} >
        <ListItemAvatar>
          {props.loading ? <Skeleton animation="wave" variant="circle" width={40} height={40} />
            : 
            <Avatar alt="Profile Picture" src={props.user.profilePictureURL}/>
        }
        </ListItemAvatar>
        <ListItemText
          primary={
            props.loading ? (
              <Skeleton animation="wave" width="40%" style={{minWidth: 100}}/>
            ) : (<Typography className={classes.profileClick} onClick={openModalPost} type="body1" style={{ fontSize: '14px', overflowWrap: 'anywhere' }}>{props.post.title}</Typography>
            )}
            secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="secondary"
                className={classes.inline}
                color="textSecondary"
                onClick={navigateToUserProfile}
              >
                {props.loading ? ( <Skeleton animation="wave" width="40%" style={{minWidth: 100}}/>) : props.user.username}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          {props.loading ? <a> </a> : 
            <Avatar 
              alt="Logo" 
              src={process.env.PUBLIC_URL + '/assets/logo/logoSVG.svg'} 
              className={getShoeRankClass(props.rank)} 
            />
          }
        </ListItemSecondaryAction>
      </ListItem>
        <PostModal 
        open={viewPost} 
        loading={userPostLoading && !userPostData} 
        isCommenting={isCommenting} 
        handleClose={() => setViewPost(false)} 
        post={modalPost} 
         
        openEditPostModal={() => { setViewPost(false);}} 
        setEditPost={setModalPost} userPostRefetch={userPostRefetch}
          />
          </div>);
}