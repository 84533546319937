import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ImagePicker = props => {
  
  const useStyles = makeStyles((theme) => ({
    imagePicker: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 16,
      marginBottom: 16,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: theme.palette.secondary.main,
      borderStyle: 'dashed',
      backgroundColor: theme.palette.background.default,
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border 0.24s ease-in-out',
    },
    thumbStyle: {
      display: 'inline-flex',
      borderRadius: props.rounded ? 32 : 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 64,
      height: 64,
      padding: 4,
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden',
    },
    avatarPreview: {
      display: 'block',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: props.rounded ? 32 : 0,
    },
    imageLoading: {
      top: 42,
      position: 'relative'
    }
  }));

  const [preview, setPreview] = useState(props.preview);

  const [errors, setErrors] = useState();

  const finishUpload = (file) => {
    props.setInputError(true);
    setPreview(URL.createObjectURL(file));
    setErrors('')
    setFileToUpload(file);
    props.setHeicLoading(false);
  }

  const { setFileToUpload } = props
  // Followed this guide for uploading profile images
  // https://www.thomasmaximini.com/upload-images-to-aws-s3-with-react-and-apollo-graphql
  const onDrop = useCallback(
    async ([file]) => {
      props.setHeicLoading(true);
      if (file) {
        if (file.type === "image/heic") {
          // TODO add a way to conver heic files to jpeg/png
          setErrors(
            'HEIC files not supported, please convert to .jpeg or .png',
          );
        } else {
          finishUpload(file)
        }
      } else {
        props.setInputError(false);
        setErrors(
          'Something went wrong. Check file type (jpeg, png) and size (max. 10 MiB)',
        );
        props.setHeicLoading(false);
      }
    },
    [setFileToUpload, props.setHeicLoading],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: 10485760, // 10 MiB
  });

  const classes = useStyles();

  const thumb = (
    <div className={classes.thumbStyle}>
      <div className={classes.thumbInner}>
        <img src={preview} alt="avatar" className={classes.avatarPreview}/>
      </div>
    </div>
  );

  return (
    <Box className={classes.imagePicker}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography variant="body2" color="textSecondary">Drop the files here ...</Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">Drop file here, or click to select file</Typography>
      )}
      {props.heicLoading && <CircularProgress size={20} className={classes.imageLoading}></CircularProgress>}
      {preview && <aside >{thumb}</aside>}
      {errors && <Typography variant="body2" color="error">{errors}</Typography>}
    </Box>);
}