import React, {Dispatch, SetStateAction} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Avatar} from "@material-ui/core";
import classnames from "classnames";

type StatsActivitySelectorProps = {
  activityList: string[];
  selectedActivity: string;
  setSelectedActivity: Dispatch<SetStateAction<string>>;
};

const StatsActivitySelector: React.FC<StatsActivitySelectorProps> = ({
                                                                       activityList,
                                                                       selectedActivity,
                                                                       setSelectedActivity
                                                                     }) => {
  
  const {root, icon, selected} = useStyles();
  
  return (
    <div className={root}>
      {
        activityList.map(activity => {
          const iconUrl = `${process.env.PUBLIC_URL}/assets/icons/${activityIconMapping[activity] || 'lift'}.svg`;
          const isSelected = activity === selectedActivity ? selected : '';
          return <Avatar
            alt={activity}
            variant={'square'}
            src={iconUrl}
            className={classnames(icon, isSelected)}
            onClick={() => setSelectedActivity(activity)}
          />
        })
      }
    </div>
  )
}

export default StatsActivitySelector;

const activityIconMapping: Record<string, string> = {
  RUN: 'run',
  BIKE: 'bike',
  SWIM: 'swim',
  SLEEP: 'sleep',
  CLIMB: 'climb',
  ALTERG: 'alterg',
  YOGA: 'yoga',
  AQUA_JOG: 'aqua_jog',
  HIKE: 'hike',
  LIFT: 'lift',
  WALK: 'walk',
  NORDIC_SKI: 'nordic',
  ROLLERSKATE: 'rollerskate',
  ROWING: 'rowing',
  ELLIPTICAL: 'elliptical',
  OTHER: 'other'
}

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      padding: '8px 0',
      marginLeft: '-6px'
    },
    icon: {
      cursor: 'pointer',
      margin: '8px',
      height: '24px',
      width: '24px',
      filter: 'invert(56%) sepia(14%) saturate(8%) hue-rotate(346deg) brightness(97%) contrast(84%)'
    },
    selected: {
      // from this website -> https://codepen.io/sosuke/pen/Pjoqqp
      filter: 'invert(25%) sepia(56%) saturate(6733%) hue-rotate(326deg) brightness(89%) contrast(93%)'
    }
  })
);