import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";

type MyStatsTableProps = {
  timeRange: string;
  distance: string;
  duration: string;
};

const MyStatsTable: React.FC<MyStatsTableProps> = ({timeRange, distance, duration}) => {
  
  const {root, tableHeader, tableRow, test} = useStyles();
  
  const tableHeaderName = timeRange === 'all' ? 'ALL TIME' : `PAST ${timeRange} DAYS`;
  
  return (
    <div className={root}>
      <div className={tableHeader}>
        {tableHeaderName}
      </div>
      <TableContainer className={test} component={Paper}>
        <Table >
          <TableBody>
            <TableRow className={tableRow}>
              <TableCell>{'Duration'}</TableCell>
              <TableCell>{duration}</TableCell>
            </TableRow>
            <TableRow className={tableRow}>
              <TableCell>{'Distance'}</TableCell>
              <TableCell>{distance}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MyStatsTable;

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '16px 16px 0 16px',
    },
    test:{
      border: '1px solid #E8E8E8',
    },
    tableHeader: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'gray',
      paddingBottom: '4px',
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: '#E8E8E8'
      }
    }
  })
);