import React, { useState, useRef, useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import { UserSearchTile } from './UserSearchTile';
import { TeamSearchTile } from './TeamSearchTile';
import { NoResults } from './NoResults';

const FILTER_NAMES = ["Athletes", "Teams"]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 320,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: 256,
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    padding: 0,
  },
  results: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
    maxHeight: "60vh",
    overflowY: 'scroll',
    borderRadius: 14,
  },
  pagination: {
    width: '100%',
    margin: "16px auto 16px auto",
  },
  loadingResults: {
    margin: 'auto',
    marginTop: 16,
    marginBottom: 16,
    display: 'block',
  },
  searchArea: {
    width: 320,
    position: 'absolute',
    right: 162,
    top: 56,
    background: 'white',
    boxShadow: '0 0 6px 0 rgba(26, 26, 26, 0.3)',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      width: 256,
    },
  },
  searchBarInput: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
    },
  },
}));

const USER_SEARCH_QUERY = gql`
  query searchUser($search: String!){
    searchUser(search: $search){
      id
      first
      last
      profilePictureURL
      username
    }
  }
`;

const TEAM_SEARCH_QUERY = gql`
  query searchTeam($search: String!){
    searchTeam(search: $search){
      id
      name
      profilePictureURL
      memberCount
      createdAt
    }
  }
`;

var firstSearch = false

export const SearchBar = (props) => {

  

  const searchBarRef = useRef()

  const [filters, setFilters] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [validSearch, setValidSearch] = useState(false);

  const handleFilters = (_, newFilters) => {    
    setFilters(newFilters);
    submitSearch(searchQuery, newFilters === 0)
    
  };


  const handleSearch = (event) => {
    const searchString = String(event.target.value)
    setSearchQuery(searchString);
    if(!validSearch && searchString.length >= 1){
      setValidSearch(true)
    } else if(searchString.length === 0) {
      setValidSearch(false)
    }
    submitSearch(searchString, filters === 0)
  };


  const handleKeypress = event => {
    //check if enter key pressed
    if(validSearch && event.key === 'Enter') {
      submitSearch(searchQuery, filters === 0);
    }
  }

  const openSearchArea = () => {
    props.handleSearchOpen()
    if(filters === ''){
      setFilters(0)
    }
  }
  const closeSearchArea = () => {
    props.handleDrawerClose()
    setFilters(0)
    setSearchQuery('')
    setValidSearch(false)
  }

  const [searchUserQuery, {data: userSearchData, loading: userSearchLoading}] = useLazyQuery(USER_SEARCH_QUERY)
  const [searchTeamQuery, {data: teamSearchData, loading: teamSearchLoading}] = useLazyQuery(TEAM_SEARCH_QUERY)

  const submitSearch = (searchString, userSearch) => {
    var query = searchString.length === 0 ? 'a' : searchString
    const searchInput = {
      variables: {
        search: query
      }
    }
    if(userSearch){
      searchUserQuery(searchInput)
    } else {
      searchTeamQuery(searchInput)
    }
    
  }


  useEffect(() => {
    if(!firstSearch){
      submitSearch(searchQuery, filters === 0);
      firstSearch = true
    }
  })
  const classes = useStyles();

  return (
    <Hidden xsDown>
      <div className={classes.root} type="form" noValidate autoComplete="off">
        <FormControl fullWidth variant="outlined" size={"small"} color={"primary"} autoComplete="off">
          <OutlinedInput
            id="search-bar"
            value={searchQuery}
            onChange={handleSearch}
            ref={searchBarRef}
            color={"primary"}
            className={classes.searchBarInput}
            onFocus={openSearchArea}
            onKeyPress={handleKeypress}
            autoComplete="off"
            placeholder={"Search " + FILTER_NAMES[filters]}
            startAdornment={
              <InputAdornment position="start">
                <IconButton className={classes.iconButton} aria-label="search" disabled={!validSearch}>
                  <SearchIcon color={validSearch ? "primary" : "disabled"}/>
                </IconButton>
              </InputAdornment>
            }
            labelWidth={0}
          />
        </FormControl>
      </div>
      {props.openSearch &&
        <Box className={classes.searchArea} onFocus={openSearchArea}>
          <Tabs
            value={filters}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            centered
            onChange={handleFilters}
          >
            <Tab label="Athletes"/>
            <Tab label="Teams"/>
          </Tabs>
          <List className={classes.results}> 
            { userSearchLoading  ? <CircularProgress className={classes.loadingResults}/> :
            (filters === 0 && userSearchData && userSearchData.searchUser) ? 
            (userSearchData.searchUser.length >= 1 ?
              userSearchData.searchUser.map((user) => (
                <UserSearchTile key={"search-user-" + user.id} user={user}  handleDrawerClose={closeSearchArea}/>
              )) :
              <NoResults/>) :
              // TODO replace <NoResults/> here with suggested users or most recent 
              (filters === 0 && <NoResults/>)
            }
            { teamSearchLoading ? <CircularProgress className={classes.loadingResults}/> :
            (filters === 1 && teamSearchData && teamSearchData.searchTeam) ? 
            (teamSearchData.searchTeam.length >= 1 ? 
              teamSearchData.searchTeam.map((team) => (
                <TeamSearchTile key={"search-team-" + team.id} team={team}  handleDrawerClose={closeSearchArea}/>
              )) :
              <NoResults/>) : 
              // TODO replace <NoResults/> here with suggested teams or most recent
              (filters === 1 && <NoResults/>)
            }
          </List>
        </Box> }
    </Hidden>);
}