import React, { useContext } from 'react';
import { AuthContext } from './auth';
import { Navigate, useLocation } from 'react-router-dom';



export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext)
  const location = useLocation();

  if (user) {
    return children
  }
  return (
    <Navigate
      replace
      to={
        {
          pathname: '/login',
          state: {
            from: location
          }
        }
      } 
    />
  );
}