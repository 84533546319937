import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Week } from './Week';
import { WeekLabel } from './WeekLabel';
import Moment from 'moment';

const WEEKS_TO_DISPLAY_IN_VIEW = 6;


const useStyles = makeStyles((theme) => ({
    root: {
      margin: '16px 16px 0 16px',
      minHeight: 'calc(100vh - 136px)',
      boxShadow: '0 0 6px 0 rgba(26, 26, 26, 0.2)',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
          margin: '8px 8px 0 8px',
      },
    },
    toolbar: {
      height: 56,
      minHeight: 56,
      boxShadow: '0 2px rgba(0,0,0,0.12)',
      [theme.breakpoints.down('sm')]: {
          paddingLeft: 4,
          paddingRight: 4,
      },
    },
    title: {
        marginRight: theme.spacing(2),
    },
    displayCurrent: {
        flexGrow: 1,
        textAlign: 'center',
    },
    iconButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    weekLabel: {
      height: 28,
      width: '100%', 
    },
    display: {
      minHeight: 'calc(100vh - 220px)',
      width: '100%', 
    },
    loading: {
      position: 'absolute',
      top: '50vh',
      left: 0,
      width: '100vw',
      height: 64,
      margin: 0,
    },
    progress: {
      width: 64,
      display: 'block',
      margin: 'auto',
    },
}));


export const UserCalendarDisplay = (props) => {
  const classes = useStyles();

  const previousButton = () => {
    props.setDate(prevDate => {
      let copy = Moment(prevDate);
      return copy.subtract(1, 'months');
    });
  }

  const nextButton = () => {
    props.setDate(nextDate => {
      let copy = Moment(nextDate);
      return copy.add(1, 'months');
    });
  }


  const calendarTitle = props.date.format('MMMM YYYY')

  const [firstDaysOfMonthView, setFirstDaysOfMonthView] = useState([]);

  useEffect(() => {

    const getFirstDayOfMonthView = () => {
      let dayOne = Moment(props.date).startOf('month').startOf('week');
      if(props.mondayFirst){
        if(dayOne.month() === props.date.month){
          dayOne.subtract(6, 'days')
        } else {
          dayOne.add(1, 'days')
        }
      } 
      return Moment(dayOne)
    }

    const getFirstDaysOfMonthView = () => {
      let weekIndex = Moment(getFirstDayOfMonthView());
      let firstDaysOfMonthView = [];
      for (let i = 0; i < WEEKS_TO_DISPLAY_IN_VIEW; i++) {
        let copy = Moment(weekIndex);
        firstDaysOfMonthView.push(copy);
        weekIndex.add(7, 'days')
        copy = Moment(weekIndex);
        if(props.date.month() !== copy.month()){
          break;
        }
      }
  
      return firstDaysOfMonthView;
    }

    let temp = getFirstDaysOfMonthView()

    if(firstDaysOfMonthView.length === 0 || temp[0].toString() !== firstDaysOfMonthView[0].toString()){
      setFirstDaysOfMonthView(temp);
    }
  }, [firstDaysOfMonthView, props.mondayFirst, props.date])

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Tooltip title={"Previous Month"} enterDelay={400} >
          <IconButton color="inherit" onClick={previousButton}>
              <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" className={classes.displayCurrent}>
            {calendarTitle}
        </Typography>
        <Tooltip title={"Next Month"} enterDelay={400} >
          <IconButton color="inherit" className={classes.iconButton} onClick={nextButton}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Grid container spacing={0} className={classes.weekLabel}>
        <WeekLabel mondayFirst={props.mondayFirst} date ={props.date} key={'week-label'}/>
      </Grid>
      {props.data && props.data.getProfileCalendarWithBoolean && <Grid container spacing={0} className={classes.display} style={{filter: props.data.getProfileCalendarWithBoolean.visibility ? '' : 'blur(3px) brightness(0.8)'}}>

        {firstDaysOfMonthView.map((firstDay, weekNumber) => {
          return (<Week
            data={props.data} 
            loading={props.loading}
            me={props.me}
            todayPost={props.todayPost}
            setTodayPost={props.setTodayPost}
            firstDay={firstDay} 
            viewMonth={props.date.month()} 
            weekCount={firstDaysOfMonthView.length} 
            weekNumber={weekNumber} 
            key={'week-'+props.date.month()+'-'+weekNumber}
            setOpenModal={props.setOpenModal}
            editPost={props.editPost} 
            setEditPost={props.setEditPost}
            setModalDate={props.setModalDate}
            openModalPost={props.openModalPost}
          />);
        })}
      </Grid>}
      {props.loading && <div className={classes.loading}>
        <div className={classes.progress}>
          <CircularProgress/>
        </div>
      </div>}

    </Paper>);
}