import React, { useContext } from 'react';
import { AuthContext } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { SuggestedUsersFollowButton } from '../Form/FollowButton/SuggestedUsersFollowButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Hidden from '@material-ui/core/Hidden';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
    fontSize: '12px'
  },
  resultItem: {
    paddingTop: 2,
    paddingBottom: 2
  }, 
  buttonRow: {
    display: 'block',
    width: '100%',
    marginTop: 8,
    marginBottom: -16,
    right: 0
  },
  followButton: {
    paddingBottom: 4,
    marginRight: 0
  },
  profileClick: {
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '0.875rem',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
}));



export const SuggestedUsersTile = (props) => {

  const navigate = useNavigate();

  const navigateToUserProfile = () => {
    navigate(`/user/${props.user.id}`)
    if(props.handleClose){
      props.handleClose()
    }
  }

  const classes = useStyles();
  const { user } = useContext(AuthContext)

  return (
    <ListItem alignItems="flex-start" className={classes.resultItem} >
        <ListItemAvatar>
          <Avatar alt="Profile Picture" src={props.user.profilePictureURL}/>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography className={classes.profileClick} onClick={navigateToUserProfile} type="body2" style={{ fontSize: '14px' }}>{props.user.first + ' ' + props.user.last}</Typography>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="secondary"
                className={classes.inline}
                color="textSecondary"
              >
                {props.user.username}
              </Typography>
              <Hidden smUp>
                <div className={classes.buttonRow}>
                  {(props.user && props.user.id && (user.id !== props.user.id)) &&
                    <SuggestedUsersFollowButton fullWidth={true} followerId={props.user.id}/>
                  }
                </div>
              </Hidden>
            </React.Fragment>
          }
        />

        <ListItemSecondaryAction className={classes.followButton} >
          <Hidden xsDown>
              {(props.user && props.user.id && (user.id !== props.user.id)) &&
                <SuggestedUsersFollowButton fullWidth={false} followerId={props.user.id}/>
              }
          </Hidden>
          
        </ListItemSecondaryAction>

      </ListItem>);
}