import React, { useContext, useState } from 'react';
import { AuthContext } from '../../auth';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { FollowButton } from '../../components/Form/FollowButton/FollowButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Hidden from '@material-ui/core/Hidden';
import { ADD_ADMIN_MUTATION, REMOVE_ADMIN_MUTATION, TEAM_QUERY, KICK_MEMBER, TRANSFER_OWNERSHIP } from '../../utils/graphql';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ReportIcon from '@material-ui/icons/Report';
import MoreVertIcon from '@material-ui/icons/MoreHoriz';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import { useNavigate } from 'react-router-dom';
// import AdminPanelSettingsIcon from '@material-ui/icons/AdminPanelSettings';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';


const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  resultItem: {
    backgroundColor: "#ffffff",
    paddingLeft: 32
  }, 
  buttonRow: {
    display: 'block',
    width: '100%',
    marginTop: 8,
    marginBottom: -8
    
  },
  followButton: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: 16
    },
  },
  profileClick: {
    cursor: 'pointer',
    overflowWrap: 'break-word',
    fontWeight: 400,
    userSelect: 'none',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
}));


export const TeamMemberUserTile = (props) => {

  const navigate = useNavigate();

  const makeAdmin = (e) => {
    e.preventDefault()
    if(props.admin){
      var removeAdminInput = {
        input: {
          userId: props.user.id,
          teamId: props.teamId
        }
      }
      removeAdminMutation({ variables: removeAdminInput })
    } else {
      var addAdminInput = {
        input: {
          userId: props.user.id,
          teamId: props.teamId
        }
      }
      addAdminMutation({ variables: addAdminInput })
    }
    setOpenPostMenu(false);
  }
  const [addAdminMutation, {loading: addAdminLoading}] = useMutation(ADD_ADMIN_MUTATION, {
    update(store, {data: result}) {
  
      const data = store.readQuery({
        query: TEAM_QUERY,
        variables: {id: props.teamId}
      })
  
      if(result.addAdmin.success){
        // should prob look for member rather than filter for not it
        var updatedMemberList = data.team.memberList.filter(user => user.id !== props.user.id)

        store.writeQuery({
          query: TEAM_QUERY,
          variables: {id: props.teamId},
          data: {
            team: {
              ... data.team,
              _typename: "Team",
              adminList: [props.user, ...data.team.adminList],
              memberList: updatedMemberList
            }
          }
        })
  
        // TODO send notification to user saying they are now an admin ???
      }
  
    },
    onError(error) {
      console.log(error)
    }
  })
  const [removeAdminMutation, {loading: removeAdminLoading}] = useMutation(REMOVE_ADMIN_MUTATION, {
    update(store, {data: result}) {
  
      const data = store.readQuery({
        query: TEAM_QUERY,
        variables: {id: props.teamId}
      })
  
      if(result.removeAdmin.success){
        // should prob look for member rather than filter for not it
        var updatedAdminList = data.team.adminList.filter(user => user.id !== props.user.id)

        store.writeQuery({
          query: TEAM_QUERY,
          variables: {id: props.teamId},
          data: {
            team: {
              ... data.team,
              _typename: "Team",
              adminList: updatedAdminList,
              memberList: [props.user, ...data.team.memberList]
            }
          }
        })
  
        // TODO send notification to user saying they are now an admin ???
      }
  
    },
    onError(error) {
      console.log(error)
    }
  })

  const navigateToUserProfile = () => {
    navigate(`/user/${props.user.id}`);
    if(props.handleClose){
      props.handleClose()
    }
  }
  const [openPostMenu, setOpenPostMenu] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const clickPostMore = (event) => {
    setOpenPostMenu(true); 
    setAnchorEl(event.currentTarget);
  }
  const [kickMemberMutation, {loading: kickMemberMutationLoading}] = useMutation(KICK_MEMBER, {
    update(store, {data: result}) {
  
      const data = store.readQuery({
        query: TEAM_QUERY,
        variables: {id: props.teamId}
      })
  
      if(result.kickMember.success){
        // should prob look for member rather than filter for not it
        var updatedMemberList = data.team.memberList.filter(user => user.id !== props.user.id)

        store.writeQuery({
          query: TEAM_QUERY,
          variables: {id: props.teamId},
          data: {
            team: {
              ... data.team,
              _typename: "Team",
              memberList: updatedMemberList,
              memberCount: data.team.memberCount - 1
            }
          }
        })
  
        // TODO send notification to user saying they are now an admin ???
      }
  
    },
    onError(error) {
      console.log(error)
    }
  })
  const makeOwner = () => {
    var transferOwnershipInput = {
      input: {
        userId: props.user.id,
        teamId: props.teamId
      }
    }
    transferOwnershipMutation({ variables: transferOwnershipInput })
    setOpenPostMenu(false);
  }
  const [transferOwnershipMutation, {loading: transferOwnershipMutationLoading}] = useMutation(TRANSFER_OWNERSHIP, {
    update(store, {data: result}) {
  
      const data = store.readQuery({
        query: TEAM_QUERY,
        variables: {id: props.teamId}
      })
  
      if(result.transferOwnership.success){

        store.writeQuery({
          query: TEAM_QUERY,
          variables: {id: props.teamId},
          data: {
            team: {
              ... data.team,
              _typename: "Team",
              owner: props.user,
            }
          }
        })
  
        // TODO send notification to user saying they are now an admin ???
      }
  
    },
    onError(error) {
      console.log(error)
    }
  })

  const kickMember = () => {
    var kickMemberInput = {
      input: {
        userId: props.user.id,
        teamId: props.teamId
      }
    }
    kickMemberMutation({ variables: kickMemberInput })
    setOpenPostMenu(false);
  }

  const getRoleLabel = () => {
    if(props.manager && props.manager.id == props.user.id){
      return "Manager: "
    } else if (props.admin){
      return "Admin: "
    } else {
      return ""
    }
  }
  const classes = useStyles();
  const { user } = useContext(AuthContext)

  return (
    <ListItem alignItems="flex-start" className={classes.resultItem} divider>
        <ListItemAvatar>
          <Avatar alt="Profile Picture" src={props.user.profilePictureURL}/>
        </ListItemAvatar>
        <ListItemText
          primary={<a>{<a style={{fontWeight: 600}}>{getRoleLabel()}</a>} <a onClick={navigateToUserProfile} className={classes.profileClick} >{props.user.first + ' ' + props.user.last} </a></a>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {props.user.username}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.followButton} >
          <Hidden smUp>
            <div className={classes.buttonRow}>
              {(props.user && props.user.id && (user.id !== props.user.id)) &&
                <FollowButton fullWidth={false} followerId={props.user.id}/>
              }
            </div>
          </Hidden>
          <Hidden xsDown>
              {(props.user && props.user.id && !props.loggedInUserAdmin && (user.id !== props.user.id)) &&
                <FollowButton fullWidth={false} followerId={props.user.id}/>
              }
            {!props.loading && props.user && props.user.id  && (user.id !== props.user.id) && props.loggedInUserAdmin && props.manager && (props.manager.id !== props.user.id) && <IconButton aria-label="edit" onClick={clickPostMore}>
              <MoreVertIcon/>              
            </IconButton>}
          {props.user && props.user.id && props.loggedInUserAdmin && <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openPostMenu}
          onClose={() => {setOpenPostMenu(false); setAnchorEl(null);}}
          disableScrollLock={ true }
        >
           {(props.manager.id === user.id) && <MenuItem onClick={makeAdmin}>
            <ListItemIcon style={{minWidth: 32}}>
              <ReportIcon fontSize="small"/>
            </ListItemIcon>
            <Typography variant="inherit" >{props.admin ? "Remove Admin" : "Make Admin"}</Typography>
          </MenuItem>}
          {(props.manager.id === user.id) && <MenuItem onClick={makeOwner}>
            <ListItemIcon style={{minWidth: 32}}>
              <ReportIcon fontSize="small"/>
            </ListItemIcon>
            <Typography variant="inherit" >Transfer Ownership</Typography>
          </MenuItem>}
          {!props.admin && <MenuItem onClick={kickMember}>
            <ListItemIcon style={{minWidth: 32}}>
              <ReportIcon fontSize="small"/>
            </ListItemIcon>
            <Typography variant="inherit" >Kick Member</Typography>
          </MenuItem>}
        </Menu>}
              
          </Hidden>
          
        </ListItemSecondaryAction>

      </ListItem>);
}