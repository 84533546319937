import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import StatsActivitySelector from "./StatsActivitySelector";
import MyStatsTable from "./MyStatsTable";
import {GET_STATS} from "./myStatsGraphQL";
import {useQuery} from "@apollo/client";
import { ProfileData } from '../../operation-result-types';

interface StatsInfo {
  user: Pick<ProfileData, "first" | "id">
}

type StatsCardProps = {loading: boolean; me: string; info: StatsInfo;};

const StatsCard: React.FC<StatsCardProps> = ({loading, me, info}) => {
  
  const {card, cardHeader, cardContent, loadingWave} = useStyles();

  const [activityList, setActivityList] = useState<string[]>();
  const [selectedActivity, setSelectedActivity] = useState<string>('RUN');
  
  var otherUserName = "My Stats"
  var userStatsId = ""
  if(info){
    if('user' in info){
      otherUserName = info.user.first + "'s Stats"
      userStatsId = info.user.id
    }
  }
  const {data: activityData} = useQuery(GET_STATS, {variables: {type: selectedActivity, userId: userStatsId}});
  
  const getTotalMin = (totalMs: number) => {
    return Math.ceil(totalMs / 60000)
  }
  
  const getTotalTimeFormat = (totalMs: number) => {
    if (!totalMs){
      return '0 min'
    }
    const hour = Math.floor(totalMs / (60000 * 60))
    const min = getTotalMin(totalMs - (hour * (60000 * 60)))
    
    const hourFormat = hour === 0 ? '' : `${hour} hr `
    const minFormat = min === 0 ? '' : `${min} min`
    
    return `${hourFormat}${minFormat}`
  }
  
  const getActivityData = (dataType: string, distance = false) => {
    if (activityData !== undefined) {
      const data = activityData.getStats[dataType];
      return distance ? `${Math.round(data.value * 100) / 100} ${data.unit.toLowerCase()}` : getTotalTimeFormat(data);
    }
    return '0';
  }
  
  useEffect(() => {
    // TODO: add all activities to the list (handle only timed items)
    const fetchedActivityList = ['RUN', 'SWIM', 'BIKE'];
    setActivityList(fetchedActivityList);
    if (fetchedActivityList.length > 0) {
      setSelectedActivity(fetchedActivityList[0])
    }
  }, [])
  
  return (
    activityList && activityList?.length > 0 ?
      <Card className={card}>
        <CardHeader
          title={
            loading ? (
            <Skeleton animation="wave" width="80%" />
          ) : <a style={{fontSize: '18px', fontWeight: 'bold'}}>{(me) ? "My Stats" : "Stats"}</a> }
          subheader={
            loading ? (
              <Skeleton animation="wave" width="40%" />
            ) :
            <StatsActivitySelector
            activityList={activityList}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
          />}
          className={cardHeader}
        />
        {loading ? (
        <div className={loadingWave}>
          <Skeleton animation="wave" width="40%" />
          <Skeleton animation="wave" width="80%" />
          <Skeleton animation="wave" width="80%" />
        </div>
        )
        : 
        <CardContent className={cardContent}>
          <MyStatsTable
            timeRange={'7'}
            distance={getActivityData('weeklyDistance', true)}
            duration={getActivityData('weeklyDuration')}
          />
          <MyStatsTable
            timeRange={'30'}
            distance={getActivityData('monthlyDistance', true)}
            duration={getActivityData('monthlyDuration')}
          />
          <MyStatsTable
            timeRange={'365'}
            distance={getActivityData('yearlyDistance', true)}
            duration={getActivityData('yearlyDuration')}
          />
          <MyStatsTable
            timeRange={'all'}
            distance={getActivityData('allTimeDistance', true)}
            duration={getActivityData('allTimeDuration')}
          />
        </CardContent> }
      </Card> :
      null
  )
};

export default StatsCard;

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 32,
    },
    card: {
      margin: 16,
    },
    cardHeader: {
      paddingBottom: '4px',
      margin: '4px 8px -20px 16px',
      alignItems: "Baseline",
    },
    cardContent: {
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    countBox: {
      backgroundColor: theme.palette.background.default,
      textAlign: 'center',
      marginBottom: 8,
      cursor: 'pointer',
    },
    loadingWave: {
      paddingLeft: 32,
      marginTop: 16,
      marginBottom: 16
    }
  })
);
