import React, { useState, useRef, useEffect } from 'react';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SettingsIcon from '@material-ui/icons/Settings';
import TeamSelectDropdown from './TeamSelectDropdown';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'moment';

const CALENDAR_VIEW_VALUE = 0

export const UserNavBar = props => {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      padding: '8px 0 0 0',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        height: '100%',
        width: '100%',
      },
    },
    spacer: {
      flexGrow: 1,
    },
    tabs: {
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
      opacity: 1, 
      [theme.breakpoints.down('xs')]: {
        margin: "4px 8px 0 8px"
      },
    },
    asdf:{
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        height: '100%',
        minWidth: '50%',
      },
    },
    teamSelect: {
      minWidth: 120,
      
    },
    select: {
      paddingRight: 8,
    },
    profilePicture: {
      margin: '4px 16px 0 16px',
    },
    settings: {
      marginRight: 16,
      position: 'absolute',
      right: 0,
    },
    addFab: {
      position: 'absolute',
      top: 8,
      right: 72,
    },
    addButton: {
      padding: '0 16px !important',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
    },
    addIcon: {
      fontSize: 18,
    },
    navbarSide: {
      whiteSpace: 'nowrap',
      width: 248,
      display: 'flex',
      position: 'relative',
    }
  }));

  const QUERY_ME = gql`
    query ME {
      me {
        id
        username
        first
        last
        profilePictureURL
        teamList {
          id
          name
          profilePictureURL
        }
      }
    }
  `;

  const QUERY_USER = gql`
    query getUser($id: ID!){
      user(id: $id){
        id
        username
        first
        last
        profilePictureURL
      }
    }
  `;

  
  const classes = useStyles();
  const settingsButtonRef = useRef()
  const {data: meData} = useQuery(QUERY_ME);

  const [getUser, { data: userData }] = useLazyQuery(QUERY_USER);
  
  
  useEffect(() => {
    if(!props.me){
      const input = {
        variables: {
          id: props.userId
        }
      }
      getUser(input)
    }
    
  }, [props.me, props.userId, getUser])
  
  const handleChange = (_, newValue) => {
    props.setViewValue(newValue)
  };

  const [openSettingsMenu, setOpenSettingsMenu] = useState(false)
  const handleSettingsClick = () => {
    setOpenSettingsMenu(!openSettingsMenu)
  }
  const handleSettingsClose = () => {
    setOpenSettingsMenu(false)
  }
  const handleSundayFirst = () => {
    props.setMondayFirst(false)
    setOpenSettingsMenu(false)
  }
  const handleMondayFirst = () => {
    props.setMondayFirst(true)
    setOpenSettingsMenu(false)
  }

  const openNewPost = () => {
    if(props.todayPost){
      props.openModalPost(props.todayPost, false)
    } else {
      props.setModalDate(Moment())
      props.setOpenModal(true)
    }
    
  }

  return (
    <div className={classes.root} >
      <span className={classes.navbarSide}>
        <Avatar 
          alt='Profile Picture'
          variant='circular' 
          src={props.me ? ((meData && meData.me) ? meData.me.profilePictureURL : "") : ((userData && userData.user) ? userData.user.profilePictureURL : "")}
          className={classes.profilePicture}
        />
        <TeamSelectDropdown 
            
            setViewValue={props.setViewValue}
            teamList={(meData && meData.me && meData.me.teamList) ? meData.me.teamList: []}
            setSelectedTeamId={0}
            teamName={userData && !props.me ? (userData.user ? userData.user.first + '\'s Calendar' : 'My Calendar'): 'My Calendar'}
            me={(meData && meData.me) ? meData.me : ""}
            user={(userData && userData.user)? userData.user : ""}
        />
      </span>
      <div className={classes.spacer}></div>
      <Tabs
        value={props.viewValue}
        className={classes.tabs}
        indicatorColor="primary"
        centered
        onChange={handleChange}
      >
        <Tab className={classes.asdf} style={{paddingLeft: 8}}label="Calendar" />
        <Tab className={classes.asdf} label="Profile" />
      </Tabs>
      <div className={classes.spacer}></div>
      <span className={classes.navbarSide}>
        {props.viewValue === CALENDAR_VIEW_VALUE && props.me && 
          <Hidden xsDown>
            <span className={classes.addFab}>
              <Tooltip title="Post Today" enterDelay={400}>
                <Fab color="primary" aria-label="add" size="small" variant="extended" className={classes.addButton} onClick={openNewPost}>
                  <AddIcon className={classes.addIcon}/>
                  Post
                </Fab>
              </Tooltip>
            </span>
            </Hidden>}
        {props.viewValue === CALENDAR_VIEW_VALUE && <div className={classes.settings} ref={settingsButtonRef}>
          <Tooltip title="Settings" enterDelay={400}>
            <IconButton 
              aria-label='settings'
              onClick={handleSettingsClick}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-settings"
            className={classes.settingsMenu}
            anchorEl={settingsButtonRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openSettingsMenu}
            onClose={handleSettingsClose}
          >
            <MenuItem onClick={handleSundayFirst}>Sundays First</MenuItem>
            <MenuItem onClick={handleMondayFirst}>Mondays First</MenuItem>
          </Menu>
        </div>}
      </span>
    </div>);

}