import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TeamMemberUserTile } from "../../components/Profile/TeamMemberUserTile"
import { AuthContext } from '../../auth';


export const TeamMemberList = props => {
  const { user } = useContext(AuthContext)
  
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: 16,
    },
    card: {
      margin: theme.spacing(2),
      maxHeight: 420, // displays 5 users, 80px per user I think
      overflow: "scroll"
    },
    cardContent: {
      paddingTop: '0px'
    },
    largeAvatar: {
      width: '128px',
      height: '128px',
    },
    media: {
      height: 190,
    },
    teamList:{
      width: '100%',
    },
    loadingResults: {
      margin: 'auto',
      marginTop: 80,
      marginBottom: 16,
      display: 'block',
      
    },
    results: {
      paddingBottom: 0,
      //margin: theme.spacing(2),
    },
  }));
  
  let loggedInUserAdmin = props.adminList.filter(function(e) { return e.id === user.id; }).length > 0;
  
  const classes = useStyles();

  if (props.error) return (<div>
    <Typography variant="h5" style={{ margin: '16px' }}>ERROR: {props.error.message}</Typography>
  </div>);
  return (
      <Card className={classes.card}>
        <CardHeader 
          title={
            props.loading ? (
            <Skeleton animation="wave" width="80%" />
          ) : <a style={{fontSize: '18px', fontWeight: 'bold'}}>Team Members</a> }
          style={{paddingBottom: 0, marginLeft: 16} }
        />
        {props.loading ? <CircularProgress className={classes.loadingResults}/> : 
        <List className={classes.results}> 
          {(props.adminList) && 
          (props.adminList.length >= 1 ?
            props.adminList.map((user) => {
              return <TeamMemberUserTile teamId={props.teamId} key={"userTile-" + user.id} user={user}  admin={true} loggedInUserAdmin={loggedInUserAdmin} manager={props.manager}/>
            }) :
            <span></span> )
          }
          {(props.memberList) && 
          (props.memberList.length >= 1 ?
            props.memberList.map((user) => {
              return <TeamMemberUserTile teamId={props.teamId} key={"userTile-" + user.id} user={user}  admin={false} loggedInUserAdmin={loggedInUserAdmin} manager={props.manager}/>
            }) :
            <span></span> )
          }
        </List>}
        
      </Card>);
}