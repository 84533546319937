import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


export const PageNotFound = (props) => {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
  <div className={classes.root}>
    <Box display="flex" justifyContent="center">
      <Box align="center">
        <Typography variant="h1">404</Typography>
        <Typography variant="h6">The page you are looking for decided to take a rest day and is not currently running</Typography>
        <Link className="text-link" to='/'><Typography variant="subtitle1">Go back to the home page</Typography></Link>
      </Box>
    </Box>
  </div>);
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginTop: 128,
    marginBottom: 128,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 256
    },
  },
}));