import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth';
import { useMutation, gql } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';

export const ResetPassword = props => {

  const location = useLocation();
  const navigate = useNavigate();

  var _isMounted = true
  const tagline = "Welcome to Curro, a collaborative running log"

  const [imagePos, setImagePos] = useState(-1)

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showPassword2: false,
    emailError: false,
    emailErrorMessage: '',
    errorMessage: '',
    passwordError: false,
    confirmError: false,
    confirmToken: true
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const context = useContext(AuthContext)
  const { user } = useContext(AuthContext)

  const RESETPASSWORD_USER_MUTATION = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input){
        token
      }
    }
  `;
  

  const [resetPasswordMutation, {loading}] = useMutation(RESETPASSWORD_USER_MUTATION, {
    update(_, {data: {resetPassword: userData}}) {
      _isMounted = false
      context.login(userData)
      navigate('/', {
        state: { 
          welcome: false,
          calendar: true,
        }
      })
      setValues({ ...values, confirmToken: false, errorMessage: ''});
    },
    onError(error) {
      console.log(error)
      if(_isMounted){
        setValues({ ...values, errorMessage: error.message });
      }
    }
  })

  const imageList = ['DSC_0811.jpg', 'DSC_1021.jpg', 'DSC_5789.jpg', 'DSC_8474.jpg', 'DSC_9056.jpg', 'IWU-44.jpg', 'MiniMeet2017-58.jpg', '_DSC3252.jpg', '_DSC5131.jpg']

  useEffect(() => {
    if(user && values.confirmToken){
      // localStorage.removeItem('token')
      // window.location.reload()
    }
    if(imagePos === -1){
      setImagePos(Math.floor(Math.random()*imageList.length))
      window.scrollTo(0, 0)
    }
  })
  const textColor = '#8AA0BD'
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'block',
      height: 'calc(100vh - 64px)',
    },
    root: {
      margin: 32,
      marginTop: -56,
      transform: 'translate(70%, 40%)',
      padding: '8px 16px 8px 16px',
      alignItems: 'center',
      flexWrap: 'wrap',  
      [theme.breakpoints.down('md')]: {
        transform: 'translate(0%, 50%)',
        margin: '-56px 0 0 0',
        padding: '2px 4px 2px 4px',
      },
      
    },
    cardContent: {
    },
    logo: {
      margin: 'auto'
    },
    welcome: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
      marginTop: '15px'
    },
    enter: {
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'Helvetica',
      fontWeight: 500,
      marginTop: '20px'
    },
    content: {
      position: 'absolute',
      display: 'block',
      zIndex: 1110,
      width: '100vw',
    },
    wrapper:{
      height: '100vh',
      width: '100vw',
      zIndex: -10,
      overflow: 'hidden',
      position: 'absolute',
      backgroundColor: '#a9a9a9',
      marginTop: -56,
    },
    image: {
      height: '100%',
      width: '100%',
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: imagePos !== -1 ? 'url(https://currodevimages.s3.amazonaws.com/background-images/'+imageList[imagePos]+')' : '',
      zIndex: -1,
      filter: 'grayscale(55%) brightness(0.5)',
      opacity: 0.9,
      overflow: 'hidden',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      margin: '30px 0 0 0',
      '& label.Mui-focused': {
        color: theme.palette.text.main,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.text.main,
        },
      },
    },
    signUp: {
      margin: '32px auto 0px auto',
      borderRadius: '21px',
      display: 'flex',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      backgroundColor: 'white',
      width: 112,
    },
    passReq: {
      textAlign: 'center',
      flexGrow: 1,
      // margin: '30px 0 0 0',
      color: "grey",
      
    },
    login: {
      color: theme.palette.primary.main,
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    textColor: {
      color: theme.palette.text.main,
    },
    snackBar: {
      position: 'absolute',
      top: 8,
    },
    tagline: {
      width: 600,
      height: 256,
      position: 'absolute',
      transform: 'translate(-60%, 90%)',
    },
    taglineMessage: {
      color: '#ffffff',
      fontWeight: 600,
      lineHeight: '70px',
      fontSize: '60px',
    }
  }));

  const classes = useStyles();

  const validateForm = (callback) => {
    var passwordErrorMessage = 'Password does not meet requirements'
    var confirmErrorMessage = 'Passwords must match'
    // password with 1 lower, 1 upper, 1 number, 1 special, and at least 8 long
    var passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W_]){1,})(?!.*\s).{8,}$/;
    var passwordValid = passwordRegex.test(values.password)
    var confirmValid = values.password === values.confirmPassword
    setValues({
      passwordError: !passwordValid, passwordErrorMessage: passwordErrorMessage,
      confirmError: !confirmValid, confirmErrorMessage: confirmErrorMessage,
    });
    if(passwordValid && confirmValid) {
      callback()
    } 
  }

  const resetUserPassword = (event) => {
    event.preventDefault();
    const query = new URLSearchParams(location.search)
    const tokenValue = query.get('resettoken')
    validateForm(() => {
      const userInput = {
        input: {
          token: String(tokenValue),
          password: String(values.password),
          confirmPassword: String(values.confirmPassword)
        }
      }
      resetPasswordMutation({variables: userInput})
    })
  };

  const submitForm = (event) => {
    event.preventDefault()
    resetUserPassword()
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <div className={classes.image}></div>
        </div>
        <Container maxWidth="sm" className={classes.container}>
          <Hidden mdDown>
            <div className={classes.tagline}>
              <Typography variant="h1" className={classes.taglineMessage}>{tagline}</Typography>
            </div>
          </Hidden>
          <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
              <div>
                {/* {TODO add bigger logo here} */}
                <Avatar alt="Logo" src={process.env.PUBLIC_URL + '/assets/logo/logoPink192.png'} className={classes.logo}/>
                <Typography variant="h4" className={classes.welcome}>Create New Password</Typography>
              </div>
              <form noValidate autoComplete="off" onSubmit={submitForm}>
              <FormControl 
                variant="outlined" 
                fullWidth 
                size="small" 
                required 
                style={{margin: '30px 0 0 0'}}
                error={values.passwordError} 
                className={classes.textField} 
              >
                <InputLabel htmlFor="reset-password" className={classes.textColor}>Password</InputLabel>
                <OutlinedInput
                  id="reset-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  label="Password"
                  variant="outlined"
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelwidth={70}
                />
              </FormControl>
              <FormControl 
                variant="outlined" 
                fullWidth 
                size="small" 
                required 
                style={{margin: '20px 0 12px 0'}}
                error={values.passwordError} 
                className={classes.textField} 
              >
              <InputLabel htmlFor="reset-confirm-password" className={classes.textColor}>Re-enter Password</InputLabel>
                <OutlinedInput
                  id="reset-confirm-password"
                  type={values.showPassword2 ? 'text' : 'password'}
                  value={values.confirmPassword}
                  label="Re-enter password"
                  variant="outlined"
                  
                  onChange={handleChange('confirmPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelwidth={70}
                />
                <FormHelperText id="create-password-error-message">{values.passwordError ? values.passwordErrorMessage : ''}</FormHelperText>
              </FormControl>
              </form> 
              <span className={classes.passReq}>Password must have 1 lowercase, 1 uppercase, 1 number, 1 special character, and be at least 8 characters long</span>
              <Button variant="contained" className={classes.textField} style={{borderRadius:'21px', textTransform: 'none'}} color="primary" fullWidth size="large" disabled={loading || values.password === '' || values.password != values.confirmPassword} type="submit" onClick={resetUserPassword}>
                {loading ? <CircularProgress color="inherit" size={26}/> : <a style={{textTransform: 'none'}}>Create and Login</a> } 
              </Button> 
            </CardContent>
          </Card>
        </Container>
      </div>
    </div>);
}
