import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DistanceHelper from "../utils/DistanceHelper";
import LinearProgress from '@material-ui/core/LinearProgress';
import { ConfirmDelete } from './Modal/ConfirmDelete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, gql } from '@apollo/client';
import { ME_QUERY } from '../utils/graphql';

export default function Equipment(props) {
      
  const handleOpenEditEquipment = () => {
    props.setEditEquipmentData(props.data)
    props.setOpenEquipmentModal(true)
  };
  
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  const handleConfirmDeleteOpen = () => {
    setOpenConfirmDelete(true);
  };

  const handleConfirmDeleteClose = () => {
    setOpenConfirmDelete(false);
  };
  const DELETE_EQUIPMENT_MUTATION = gql`
    mutation deleteEquipment($equipmentId: ID!) {
      deleteEquipment(equipmentId: $equipmentId) {
        message
        success
      }
    }
  `;
  const [deleteEquipmentMutation, { deleteLoading }] = useMutation(DELETE_EQUIPMENT_MUTATION, {
    update(store, { data: {deleteEquipment} }) {

      const data = store.readQuery({
        query: ME_QUERY
      })

      const updatedEquipmentList = data.me.equipmentList.filter((equipment) => {
        return equipment.id !== props.data.id
      })
      
      store.writeQuery({
        query: ME_QUERY,
        data: {
          me: {
            ...data.me,
            __typename: "User",
            equipmentList: updatedEquipmentList
          }
        }
      })

    },
    onError(error) {
      console.log(error)
      console.log(error.message)
    }
  })

  const [state, setState] = React.useState({
    equipmentId: props.data.id,
    ...props.data
  });
  const deleteEq = () => {
    setState({
        equipmentId: props.data.id,
        name: props.data.name,
        type: props.data.type,
        limit: {
            value: props.data.limit.value,
            unit: props.data.limit.unit
        },
        active: props.data.active
    });
    var input = { equipmentId: props.data.id }
    deleteEquipmentMutation({variables: input})
    handleConfirmDeleteClose()
  };
    var distance = {value: props.data.usage.value, unit: props.data.usage.unit};
    var usageValue = DistanceHelper.convertDistanceToUnit(distance, props.data.limit.unit)

    // Shows when equipment is over total mileage
    var tooMuchColor = "#8C8C8C"
    var equipName = props.name
    if(Math.round(usageValue*100)/100 > props.capacity){
      tooMuchColor = "#DC1E65"
      equipName += " - EXPIRED!"
    }

    return (
      <React.Fragment>
        <ListItem style={{paddingBottom: 0, paddingTop: 0}}>
          <ListItemText 
            secondary={equipName} 
          /> 

        </ListItem>
        <ListItem style={{paddingTop: 0, paddingRight: 0}}>
          <LinearProgress style={{width: '100%', marginRight: 10}} variant="determinate" value={usageValue < props.capacity ? Math.round(100 * parseFloat(usageValue) / parseFloat(props.capacity)) : 100}/>
          <ListItemText 
            style={{width: '150px', paddingTop: 0, marginRight: '72px'}}
            secondary={Math.round(usageValue*100)/100 + " " + (props.data.limit.unit).toLowerCase() + " / " + props.capacity + " " + props.data.limit.unit.toLowerCase()} 
            secondaryTypographyProps={{style: {color: tooMuchColor}}}
          />
          {props.edit && <ListItemSecondaryAction style={{paddingRight: '0', transform: 'translateY(-58%)'}}>
            <IconButton edge="end" aria-label="edit" onClick={handleOpenEditEquipment}>
              <EditIcon fontSize="small"/>
            </IconButton>
            <IconButton edge="end" aria-label="edit" disabled={deleteLoading} onClick={handleConfirmDeleteOpen}>
              <DeleteIcon fontSize="small"/>
              {deleteLoading && <CircularProgress size={24}  />}
            </IconButton>
          </ListItemSecondaryAction>
          }
        </ListItem>
        <ConfirmDelete
            open={openConfirmDelete}
            handleClose={handleConfirmDeleteClose}
            action={deleteEq}
            actionLabel={"Delete"}
            title={"Delete " + props.data.name + "?"}
            description={"Are you sure you wish to delete " + state.name + "? This cannot be undone."}
          />
      </React.Fragment>
      
    );
}