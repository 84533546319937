import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    cell: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      padding: 4,
    },
    today: {
      color: theme.palette.primary.main
    }
}));

const mondayWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const sundayWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const WeekLabel = (props) => {
  const classes = useStyles();

  const isToday = (someDate) => {
    return someDate.isSame(moment(), 'day')
  }
  
  const getDayLabelClass = (dayIndex) => {
    var dayLabelClass = `${classes.cell}`
    dayIndex = props.mondayFirst ? (dayIndex + 1) % 7 : dayIndex
    if(isToday(props.date) && dayIndex === props.date.day()){
      dayLabelClass = `${dayLabelClass} ${classes.today}`
    }
    return dayLabelClass
  }

  return (
    <Grid container item xs={12} spacing={props.team ? 3 : 0}>
      {props.team && <Grid item xs={4} sm={4} md key={'Athlete'}>
        <Box className={classes.cell}>
          Athlete
        </Box>    
      </Grid>}
      {!props.team ? 
        <Hidden smUp>
          {(props.mondayFirst ? mondayWeek : sundayWeek).map((dayLabel) => (
            <Grid item xs key={dayLabel}>
              <Box className={classes.cell}>
                {dayLabel[0]}
              </Box>
            </Grid>
          ))}
        </Hidden> : 
        <Hidden mdUp>
            <Grid item xs key={props.date.day()}>
              <Box className={classes.cell}>
                {sundayWeek[props.date.day()]}
              </Box>
            </Grid>
        </Hidden> 
      }
      {!props.team ? <Hidden xsDown>
        {(props.mondayFirst ? mondayWeek : sundayWeek).map((dayLabel, dayIndex) => (
          <Grid item xs key={dayLabel}>
            <Box className={classes.cell}>
              {dayLabel}
            </Box>
          </Grid>
        ))}
      </Hidden> :
      <Hidden smDown>
        {(props.mondayFirst ? mondayWeek : sundayWeek).map((dayLabel, dayIndex) => (
          <Grid item xs key={dayLabel}>
            <Box className={getDayLabelClass(dayIndex)}>
              {dayLabel}
            </Box>
          </Grid>
        ))}
      </Hidden>}
      {!props.team && <Hidden mdDown>
        <Grid item xs key={'total'}>
          <Box className={classes.cell}>
            Total
          </Box>    
        </Grid>
      </Hidden>}
    </Grid>);
}