import React, { useState } from 'react';
import { NewActivityModal } from '../../components/Modal/NewActivityModal';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { useQuery, useLazyQuery, NetworkStatus } from '@apollo/client';
import { PostModal } from '../../components/Modal/PostModal';
import { TeamNavBar } from '../../components/Calendar/TeamNavBar';
import { TeamCalendarDisplay } from './TeamCalendarDisplay';
import { Team } from '../Team/Team';
import { GET_TEAM_CALENDAR_PAGINATOR_QUERY, GET_POST_BY_ID_QUERY } from '../../utils/graphql';
import Moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    addFab: {
        position: 'fixed',
        bottom: 16,
        right: 16,
    },
    root: {
        flexGrow: 1,
    }
}));

const CALENDAR_VIEW_VALUE = 0
const OVERVIEW_VIEW_VALUE = 1
const TEAM_CALENDAR_LIMIT = 10
let page = 1

var moreDetailPost = {}

export const TeamCalendar = (props) => {
    const classes = useStyles();
    const location = useLocation();

    const [viewPost, setViewPost] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalPost, setModalPost] = useState(null)
    const [editPost, setEditPost] = useState(null)
    const [modalDate, setModalDate] = useState(Moment());
    const [viewValue, setViewValue] = React.useState((location.state && location.state.calendar) ? CALENDAR_VIEW_VALUE : OVERVIEW_VIEW_VALUE);
    const [date, setDate] = useState(Moment());
    const [mondayFirst, setMondayFirst] = useState(true)
    const [isCommenting, setIsCommenting] = useState(false)
    const [todayPost, setTodayPost] = useState(null)

    const { teamid } = useParams()

    const getTeamCalendarDateFormat = () => {
      var temp = Moment(date).format('YYYY-MM-DD')
      return temp.toString()
    }

    const dateInput = getTeamCalendarDateFormat()

    const {data, loading, error, fetchMore, networkStatus: teamCalendarNetworkStatus} = useQuery(GET_TEAM_CALENDAR_PAGINATOR_QUERY, {variables: {teamId: teamid, date: dateInput, limit: TEAM_CALENDAR_LIMIT, page: page}, notifyOnNetworkStatusChange: true})

    const [getUserPost, { data: userPostData, loading: userPostLoading, refetch: userPostRefetch, networkStatus }] = useLazyQuery(GET_POST_BY_ID_QUERY, {
        onCompleted: (result) => {
            if (networkStatus === NetworkStatus.refetch){
                setModalPost(result.post)
            } else {
                moreDetailPost[result.post.id] = result.post
                setModalPost(result.post)
                setViewPost(true)
            }
            return 
        },
        notifyOnNetworkStatusChange: true,
        onError: (error) => console.log(error)
    })

    const fetchMoreTeamCalendar = () => {
        console.log("fetch more calendar")
        if(fetchMore && data.getTeamCalendarPagination.hasMore) {
            fetchMore({variables: {page: data.getTeamCalendarPagination.nextPage, limit: TEAM_CALENDAR_LIMIT}})
        }
    } 

    const openModalPost = (post, commentOnPost) => {
        setIsCommenting(commentOnPost)
        // get post data
        getUserPost({
            variables: {id: post.id},
        })
        if(userPostData && userPostData.post && !userPostLoading){
            setModalPost(userPostData.post)
            setViewPost(true)
        }
    }

    if(error){
        return <div className={classes.root}>{error}</div>
    }
    
    return (
        <div className={classes.root}>
            <TeamNavBar 
                viewValue={viewValue} 
                setViewValue={setViewValue} 
                setModalDate={setModalDate}
                setOpenModal={setOpenModal}
                openModalPost={openModalPost}
                todayPost={todayPost}
                setTodayPost={setTodayPost}
                 
                teamId={teamid} 
                mondayFirst={mondayFirst} 
                setMondayFirst={setMondayFirst}
            />
            {viewValue === CALENDAR_VIEW_VALUE && 
                <TeamCalendarDisplay
                     
                    editPost={editPost}
                    setEditPost={setEditPost}
                    teamId={teamid} 
                    todayPost={todayPost}
                    setTodayPost={setTodayPost}
                    date={date} 
                    data={data}
                    loading={loading || userPostLoading}
                    setDate={setDate} 
                    mondayFirst={mondayFirst}
                    setOpenModal={setOpenModal}
                    setModalDate={setModalDate}
                    openModalPost={openModalPost}
                    fetchMoreTeamCalendar={fetchMoreTeamCalendar}
                    teamCalendarNetworkStatus={teamCalendarNetworkStatus}
                />}
            {viewValue === OVERVIEW_VIEW_VALUE && <Team teamId={teamid} />}
            <NewActivityModal openModal={openModal} handleClose={() => setOpenModal(false)} modalDate={modalDate} editPost={editPost} setEditPost={setEditPost}/>
            {viewValue === CALENDAR_VIEW_VALUE && 
                <Hidden smUp>
                    <span className={classes.addFab}>
                        <Fab color="primary" aria-label="add" size="small" className={classes.addButton} onClick={() => {
                                setModalDate(Moment())
                                setOpenModal(true)
                            }
                        }>
                            <AddIcon className={classes.addIcon}/>
                        </Fab>
                    </span> 
                </Hidden>}
            <PostModal 
                open={viewPost} 
                loading={userPostLoading && !userPostData} 
                isCommenting={isCommenting} 
                handleClose={() => setViewPost(false)} 
                post={modalPost} 
                 
                openEditPostModal={() => { setOpenModal(true); setViewPost(false);}} 
                setEditPost={setEditPost} userPostRefetch={userPostRefetch}
            />
        </div>);
}