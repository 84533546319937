import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { TeamCard } from './TeamCard';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TeamMemberList } from './TeamMemberList';


type TeamProps = {
  teamId: string; 
}

export const Team: React.FC<TeamProps> = ({ teamId }) => {
  const MEMBER_FRAGMENT = gql`
    fragment MemberData on User {
      id
      username
      first
      last
      profilePictureURL
    }
  `;
const TEAM_MEMBER_QUERY = gql`
query getTeam($id: ID!){
    team(id: $id) {
      id
      name
      private
      description
      profilePictureURL
      memberCount
      createdAt
      owner {
        id
        username
      }
      adminList {
        ...MemberData
      }
      memberList {
      ...MemberData
      }
    }
  }
  ${MEMBER_FRAGMENT}
  `; 
  //TODO ask brad about adding each members data to query rather than just id
  //should I make another call, or use this one bc it's right there
  const { loading, error, data } = useQuery(TEAM_MEMBER_QUERY, {variables: {id: teamId}});

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={4}>
          <TeamCard loading={loading} error={error} data={data} />
        {data && data.team && 
          <TeamMemberList
            adminList={data.team && data.team.adminList.length >= 1 ? data.team.adminList : []}
            memberList={data.team && data.team.memberList.length >= 1 ? data.team.memberList : []}
            teamId={teamId}
            manager={data.team && data.team.owner ? data.team.owner : null}
            loading={loading}
            
          />}
        </Grid>

      </Grid>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 64px)'
  },
}));