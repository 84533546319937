import React, { useContext } from 'react';
import { AuthContext } from '../../auth';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';


const Footer = props => {
  const useStyles = makeStyles((theme) => ({
    logo: {
      marginTop: 32,
    },
    textButton: {
      cursor: 'pointer',
      fontWeight: 600,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    linkButtons: {
      width: '100vw',
      maxWidth: 464,
      margin: 'auto',
      marginTop: 8,
      marginBottom: 32,
    },
    outsideLink: {
      color: 'inherit',
      textDecoration: 'none',
    },
    secondaryText: {
      fontWeight: 300,
    }
  }));

  const classes = useStyles();

  const navigate = useNavigate();

  const { user, logout } = useContext(AuthContext)

  const client = useApolloClient();


  const logoutUser = (client) => {
    client.cache.reset()
    signOut()
    navigate('/login');
  };

  const MUTATION_SIGNOUT = gql`
    mutation {
      signOut
    }
  `;

  const [signOut] = useMutation(MUTATION_SIGNOUT, {
    update(_, {data: {signOut: success}}) {
      logout()
      console.log(success)
    },
    onError(error) {
      console.log(error.message)
    }
  });


  return (
  <div className="footer">
    <Button onClick={() => navigate('/calendar')} className={classes.logo}>
      <Avatar alt="Logo" src={process.env.PUBLIC_URL + '/assets/logo/logoPink192.png'}/>
    </Button>
    <div className={classes.linkButtons}>
     <Hidden smDown>
        <Grid 
          container
          direction="row"
          justify="space-evenly"
        >
          {user ? 
            <Grid item>
              <Typography variant={'overline'} className={classes.textButton} onClick={() => logoutUser(client)}>Logout</Typography>
            </Grid>: 
            <Grid item>
              <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/login')}>Login</Typography>
            </Grid>
          }
          <Grid item>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/about')}>About Us</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/privacy')}>Privacy Policy</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/terms')}>Terms of Service</Typography>
          </Grid>
          <Grid item>
            <Typography variant={'overline'} className={classes.textButton}><a href={'https://forms.gle/7JdpvaG6YZ657tBv5'} className={classes.outsideLink}>Feedback</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'caption'} className={classes.secondaryText}>&copy;2023 Curro LLC. All rights reserved.</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid 
          container
          direction="row"
          justify="space-evenly"
        >
         {user ? 
            <Grid item xs={12}>
              <Typography variant={'overline'} className={classes.textButton} onClick={() => logoutUser(client)}>Logout</Typography>
            </Grid>: 
            <Grid item xs={12}>
              <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/login')}>Login</Typography>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/about')}>About Us</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/privacy')}>Privacy Policy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'overline'} className={classes.textButton} onClick={() => navigate('/terms')}>Terms of Service</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'overline'} className={classes.textButton}><a href={'https://forms.gle/7JdpvaG6YZ657tBv5'} className={classes.outsideLink}>Feedback</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'caption'} className={classes.secondaryText}>&copy;2023 Curro LLC. All rights reserved.</Typography>
          </Grid>
        </Grid>
      </Hidden>
    </div>

  </div>);
}

export default Footer;