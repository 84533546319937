import React, { useState }  from 'react';
import { AllCaughtUp } from '../../components/Post/AllCaughtUp'
import { useQuery } from '@apollo/client';
import { Waypoint } from 'react-waypoint';
import { NewActivityModal } from '../../components/Modal/NewActivityModal';
import { makeStyles } from '@material-ui/core/styles';
import { PostCard } from '../../components/Post/PostCard';
import { RecommendedUsers } from '../../components/RecommendedUsers/RecommendedUsers';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { GET_NEWSFEED_QUERY, GET_POST_BY_DATE, GET_RECENT_USER, GET_TOP_POSTS } from '../../utils/graphql';
import Moment from 'moment';
import { SuggestedUsersTile } from "../../components/Feed/SuggestedUsersTile";
import { TopPostTile } from "../../components/Feed/TopPostTile";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ListSubheader from '@material-ui/core/ListSubheader';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "block",
    width: '100%',
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    overflowY: 'hidden',
  },
  list: {
    width: "99%",
    display: "block",
    margin: "auto",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    paddingBottom: 32,
  },
  listItem: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,

  },
  loadingPostProgress: {
    width: 40,
    padding: 16,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 'auto',
  },
  addFab: {
    position: 'fixed',
    bottom: 16,
    right: 16,
  },
  noPost: {
    padding: 16,
    margin: 16,
    textAlign: 'center',
  },
  recentUsers: {
    width: '100%',
    paddingTop: 8,
    top: 0
  },
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      padding: '0 50px 0 50px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0 0'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 0 0'
    },
    padding: '0 225px 0 225px'
  },
  suggestedTitle: {
    lineHeight: '30px',
    fontSize: 16,
    backgroundColor: '#FBFBFB'
  }
}));

var allCaughtUp = false
const NEWSFEED_LIMIT = 10
let page = 1

export const Feed = (props) => {

  const [openModal, setOpenModal] = useState(false)
  const [seenAllPost, setSeenAllPost] = useState(allCaughtUp)
  const [editPost, setEditPost] = useState(null)
  
  const sampleUser = {
    _id: "5f8d1b4e66ebae0038491572",
    username: " ",
    first: "Brad",
    last: "Hodkinson",
    profilePictureURL: "https://avatars1.githubusercontent.com/u/39724942?s=460&u=3140403237128bbe7f1daba46e28bb09bec4b2c0&v=4"  
  }
  const sampleUserPost = {
    _id: "1",
    title: "jaklsdfjoiaweociawenoiwancoiwanoieno",
    note: "Woke up feeling really tired and sore from rock climbing last night, so hit snooze and slept in. Going to run after class in the evening. // Ran after 4.5 hours of back to back class with Brad - felt really sleepy and unmotivated and absolutely did not want to get out the door, but once we got started I felt alright! Out and back along Escarpment which I actually don't mind at all despite my intense hatred of out and backs. \"Will it loop?\" has become the motto of nearly every run I've done here. In other news, I reached out to Stiles and we're talking half training on Friday yay! I feel like I'm betraying my MD self and maybe setting myself up for something too ambitious given that I have not run over 12 miles in a single run in like 3 years but hoping muscle memory of bigger mileage days kicks in.",
  }
  const sample_user_post = {
    id: "1",
    title: "My first log",
    note: "Woke up feeling really tired and sore from rock climbing last night, so hit snooze and slept in. Going to run after class in the evening. // Ran after 4.5 hours of back to back class with Brad - felt really sleepy and unmotivated and absolutely did not want to get out the door, but once we got started I felt alright! Out and back along Escarpment which I actually don't mind at all despite my intense hatred of out and backs. \"Will it loop?\" has become the motto of nearly every run I've done here. In other news, I reached out to Stiles and we're talking half training on Friday yay! I feel like I'm betraying my MD self and maybe setting myself up for something too ambitious given that I have not run over 12 miles in a single run in like 3 years but hoping muscle memory of bigger mileage days kicks in.",
    author: {
      id: "5f8d1b4e66ebae0038491572",
      username: "bradh43",
      first: "Brad",
      last: "Hodkinson",
      profilePictureURL: "https://avatars1.githubusercontent.com/u/39724942?s=460&u=3140403237128bbe7f1daba46e28bb09bec4b2c0&v=4"
    },
    tagList: [],
    activityList: [
      {
        id: "5f95c69cef639bc2f5ccc69f",
        type: "RUN",
        distance: {
          value: 10.4,
          unit: "KM"
        },
        // duration: 3060000
        duration: null

      }
    ],
    likeList: [],
    commentList: [],
    createdAt: "1603683112465",
  }

  // loading will always be true if no posts, see https://github.com/apollographql/apollo-client/issues/6334
  const { data, loading, fetchMore, networkStatus } = useQuery(GET_NEWSFEED_QUERY, {variables: {page: page, limit: NEWSFEED_LIMIT}, notifyOnNetworkStatusChange: true});
  const { data: recentUserData, loading: recentUserDataLoading, error: recentUserDataError }  = useQuery(GET_RECENT_USER); 
  const { data: topPostData, loading: topPostLoading, error: topPostError }  = useQuery(GET_TOP_POSTS); 
  const { data: todayPost, loading: todayPostToday, error: todayPostError } = useQuery(GET_POST_BY_DATE, {variables: { date: Moment().format('YYYY-MM-DD').toString()}})

  const fetchMorePosts = () => {
    if(data.getFeed.paginator.hasNextPage) {
      fetchMore({variables: {page: data.getFeed.paginator.next, limit: NEWSFEED_LIMIT}})
    } else {
      if(!allCaughtUp){
        allCaughtUp = true
        setSeenAllPost(true)
      }
    }
  }

  const openNewPost = () => {
    if(!todayPostToday && todayPost && todayPost.getPostByDate){
      if(todayPost.getPostByDate.exists){
        // Edit post that was already posted today
        setEditPost(todayPost.getPostByDate.post)
      }
      // Create New Post for today
      setOpenModal(true)
    }
  }
  const classes = useStyles();
  


  if(recentUserDataLoading || todayPostToday || topPostLoading || loading){
    return (
      <Grid container className={classes.gridContainer} spacing={1} columns={2} justifyContent="center">
      <Grid item xs={12} md={8}><div className={classes.mainContent}>
        <Hidden mdUp>
        <List className={classes.recentUsers} subheader={<Skeleton animation="wave" width="70%" style={{left: 16}}/>}>
        <ListItem key={3} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={4} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={5} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={6} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={7} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          </List>
          </Hidden>
        <List className={classes.list}>
          <ListItem key={1} className={classes.listItem}>
            <PostCard post={sample_user_post} loading={true}/>
          </ListItem>
          <ListItem key={2} className={classes.listItem}>
            <PostCard post={sample_user_post} loading={true}/>
          </ListItem>
          <ListItem key={3} className={classes.listItem}>
            <PostCard post={sample_user_post} loading={true}/>
          </ListItem>
          <div className={classes.loadingPostProgress}><CircularProgress/></div>
      </List>
    </div>
    </Grid>
        <Hidden smDown>
        <Grid item md={4} style={{zIndex: 5, minWidth:280}}>
        <List className={classes.recentUsers} subheader={<Skeleton animation="wave" width="70%" style={{left: 16}}/>}>
        <ListItem key={3} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={4} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={5} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={6} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={7} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          </List>
          <List className={classes.recentUsers} subheader={<Skeleton animation="wave" width="70%" style={{left: 16}}/>}>
        <ListItem key={3} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={4} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={5} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={6} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          <ListItem key={7} className={classes.listItem} style={{padding: '0 0 0 0'}}>
            <TopPostTile user={sampleUser} post={sampleUserPost} loading={true}/>
          </ListItem>
          </List>
        </Grid>
        </Hidden>
    </Grid>
    )
  }
  
  return (
    
  <div>
    <Grid container className={classes.gridContainer} spacing={1} columns={2} justifyContent="center">
      <Grid item xs={12} md={8}>
      <div className={classes.mainContent}>
      {
      <List className={classes.list}>
      <Hidden mdUp>
      <Box style={{zIndex: 5, width: '100%'}}>
          <List className={classes.recentUsers}
          subheader={
            <ListSubheader className={classes.suggestedTitle} component="div" id="nested-list-subheader">
             Top Posts of the Week
            </ListSubheader>
          }
          > 
          {(topPostData) && 
          (topPostData.getTopPosts.length >= 1 ?
            topPostData.getTopPosts.map((post, index) => {
              return <TopPostTile key={"postTile-" + post._id} user={post.author} rank={index} post={post} />
            }) :
            <span>No Users Found</span> )
          }
          </List>

        </Box>
      </Hidden>
        {!data || !data.getFeed.posts || data.getFeed.posts.length === 0 && 
          <React.Fragment key={"no-posts"}>
          <ListItem className={classes.listItem}>
            <Typography variant={'h5'} className={classes.noPost}>Looks like there are no posts yet. Try making a new post or following users.</Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <RecommendedUsers />
          </ListItem>
        </React.Fragment>
        }
        {data.getFeed.posts.map((post, index) => (
          <React.Fragment key={"post" + post.id}>
            <ListItem className={classes.listItem}>
            <PostCard post={post} openEditPostModal={() => setOpenModal(true)} setEditPost={setEditPost} />
            </ListItem>
            { (index === data.getFeed.posts.length - 4) && (
              <Waypoint onEnter={fetchMorePosts}/>
            )}
          </React.Fragment>
        ))}
        { seenAllPost && <AllCaughtUp/>}
              {/* TODO: update loading screen */}
        {(networkStatus === 3) && <div className={classes.loadingPostProgress}><CircularProgress/></div>}
      </List>
       
    }
     
    </div>
      </Grid>
      <Hidden smDown>
      <Grid item md={4} style={{zIndex: 5, minWidth:280}} scr>
      <Box style={{zIndex: 5, minWidth:330, position: 'fixed', maxHeight: '100vh', overflow: 'auto'}}>
          <List className={classes.recentUsers}
          subheader={
            <ListSubheader className={classes.suggestedTitle} component="div" id="nested-list-subheader">
             Top Posts of the Week
            </ListSubheader>
          }
          > 
          {(topPostData) && 
          (topPostData.getTopPosts.length >= 1 ?
            topPostData.getTopPosts.map((post, index) => {
              return <TopPostTile key={"postTile-" + post._id} user={post.author} rank={index} post={post} />
            }) :
            <span style={{marginLeft: 16}}>No Posts Found</span> )
          }
          </List>
          <List className={classes.recentUsers}
          subheader={
            <ListSubheader className={classes.suggestedTitle} component="div" id="nested-list-subheader">
             Suggested Users
            </ListSubheader>
          }
          > 
          {(recentUserData) && 
          (recentUserData.getRecentUsers.length >= 1 ?
            recentUserData.getRecentUsers.map((user) => {
              return <SuggestedUsersTile key={"userTile-" + user.id} user={user} />
            }) :
            <span>No Users Found</span> )
          }
          </List>
        </Box>
      </Grid>
      </Hidden>
      </Grid>

    <NewActivityModal openModal={openModal} handleClose={() => setOpenModal(false)} editPost={editPost} setEditPost={setEditPost}/>
    <span className={classes.addFab}>
        <Fab color="primary" aria-label="add" onClick={openNewPost}>
            <AddIcon />
        </Fab>
    </span>
  </div>);
}