import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import { useMutation } from '@apollo/client';
import { CREATE_REPORT } from '../../utils/graphql'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  modal: {
    top: 48,
    overflow: 'hidden'
  },
  paper: {
    position: 'absolute',
    width: '40%',
    minHeight: '30%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    padding: '0 16px 16px 16px',
    margin: 0,
    overflow: 'scroll',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '100%', 
      width: '100%',
    },
  },
  spacer: {
    flexGrow: 1,
    textAlign: 'center',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textField: {
    margin: '16px 0 0 0',
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  or: {
    textAlign: 'center',
    display: 'block',
    paddingTop: 4,
    marginBottom: -16,
  }
}));
var _isMounted = false;
export const ReportPostModal = (props) => {

  const classes = useStyles();
  const [state, setState] = React.useState({
    type: undefined,
    note: "",
  });

  const save = () => {
    const createReportInput = {
      input: {
        note: state.note,
        type: state.type,
        postId: props.post.id
      }
    }
    setState({
      type: "",
      name: ""
    });
    console.log(createReportInput)
    props.handleClose()
    createReportMutation({ variables: createReportInput })
  }
  const cancel = () => {
    setState({
      type: "",
      name: ""
    });
    _isMounted = false
    props.handleClose();
  }
  const [createReportMutation, { loading: createReportLoading }] = useMutation(CREATE_REPORT, {
    
  })
  const reportTypes = [
    ["SPAM", "Spam"],
    ["NUDITY", "Nudity"],
    ["HATESPEECH", "Hate Speech"],
    ["BULLYING","Bullying"],
    ["INAPPROPRIATE", "Inappropriate"],
    ["MISCELLANEOUS", "Miscellaneous"]
  ]
  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: String(event.target.value) });
  };

  const body = (
    <div className={classes.paper}>
      <Toolbar disableGutters>
        <Button onClick={cancel}>Cancel</Button>
        <Typography variant="h6" className={classes.spacer}>Report Post</Typography>
        <Button onClick={save} color="primary">
          Report
          {false && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Toolbar>
      <form onSubmit={save}>
        <FormControl variant="outlined" fullWidth className={classes.inputField}>
                <InputLabel id="equipment-select">Reason for Reporting</InputLabel>
                <Select
                  id="report-type"
                  value={state.type}
                  onChange={handleChange('type')}
                  label="Types"
                >
                {
                    reportTypes
                      .map((type) => {
                        return <MenuItem key={type[0]} value={type[0]}>{type[1]}</MenuItem>
                      })
                  }
                </Select>
              </FormControl>
        <TextField 
          fullWidth 
          className={classes.textField} 
          onChange={handleChange('note')} 
          label="Note"
          multiline 
          rows={7} 
          variant="outlined" 
          inputProps={{maxLength : 300}}
        />
      </form>
    </div>
  );

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      className={classes.modal}
      open={props.openModal}
      onClose={props.handleClose}
      disableBackdropClick
      aria-labelledby="simple-modal-tditle"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
   
  );
}