import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useNavigate } from 'react-router-dom';

export default function TeamListItem(props) {
    const navigate = useNavigate();
    const useStyles = makeStyles((theme) => ({
      button: {
        position: "absolute",
        right: 26,
      },
      profilePicture: {
        cursor: 'pointer',
        height: '60px',
        width: '60px',
        margin: '0 20px 0 0'
      },
      profileClick: {
        cursor: 'pointer',
        userSelect: 'none',
        "&:hover": {
          textDecoration: 'underline',
          color: theme.palette.secondary.main,
        },
      }
    }))
    const navigateToTeamPage = () => {
      navigate(`/team/${props.data.id}`)
    }
    
    const classes = useStyles();

    return (
      <React.Fragment>
        <ListItem >
          <ListItemAvatar>
            <Avatar 
            variant="square" 
            className={classes.profilePicture}
            src={props.data.profilePictureURL}
            onClick={navigateToTeamPage}
            />
          </ListItemAvatar>
          <ListItemText 
            primary={<span className={classes.profileClick} onClick={navigateToTeamPage}>{props.data.name}</span>} 
            secondary={props.data.memberCount + " Member" + (props.data.memberCount > 1 ? "s" : "")}
          /> 
        </ListItem>
        
      </React.Fragment>
      
    );
}