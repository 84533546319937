import React, { useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SettingsIcon from '@material-ui/icons/Settings';
import TeamSelectDropdown from './TeamSelectDropdown';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'moment';

const CALENDAR_VIEW_VALUE = 0

export const TeamNavBar = props => {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      padding: '8px 0 0 0'
    },
    spacer: {
      flexGrow: 1,
    },
    tabs: {
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
      opacity: 1, 
    },
    teamSelect: {
      minWidth: 120,
      
    },
    select: {
      paddingRight: 8,
    },
    profilePicture: {
      margin: '4px 16px 0 16px',
    },
    settings: {
      marginRight: 16,
      position: 'absolute',
      right: 0,
    },
    addFab: {
      position: 'absolute',
      top: 8,
      right: 72,
    },
    addButton: {
      padding: '0 16px !important',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
    },
    addIcon: {
      fontSize: 18,
    },
    navbarSide: {
      whiteSpace: 'nowrap',
      width: 248,
      display: 'flex',
      position: 'relative',
    }
  }));

  const QUERY_ME = gql`
    query ME {
      me {
        id
        username
        first
        last
        profilePictureURL
        teamList {
          id
          name
          profilePictureURL
        }
        requestedTeamList {
          id
        }
      }
    }
  `;

const TEAM_MEMBER_QUERY = gql`
  query getTeam($id: ID!){
    team(id: $id) {
      id
      name
      profilePictureURL
    }
  }
`;

  
  const classes = useStyles();
  const settingsButtonRef = useRef()
  const {data, loading, error} = useQuery(QUERY_ME);
  const {data: teamData} = useQuery(TEAM_MEMBER_QUERY, {variables: {id: props.teamId}});

  const [joined, setJoined] = useState(false);
  const [requestPending, setRequestPending] = useState(false);

  // Check if the user is a part of the team
  if(!loading && data && props.teamId){
    
    var foundTeamFlag = false
    var requestedTeamFlag = false
    // loop through all of their teams and check for team id
    for(var i=0; i<data.me.teamList.length; i++){
      if(data.me.teamList[i].id === props.teamId){
        foundTeamFlag = true

        break;
      }
    }
    //if already in team list, don't need to check requestedTeamList as well
    if(!foundTeamFlag) {
      for(var j=0; j<data.me.requestedTeamList.length; j++){
        if(data.me.requestedTeamList[j].id === props.teamId){
          requestedTeamFlag = true
          break;
        }
      }
    }


    // prevent the page from doing too many re-renders
    if(joined !== foundTeamFlag){
      setJoined(foundTeamFlag) 
    }

    if(requestPending !== requestedTeamFlag){
      setRequestPending(requestedTeamFlag)
    }
  } 
  
  const handleChange = (_, newValue) => {
    props.setViewValue(newValue)
  };

  const [openSettingsMenu, setOpenSettingsMenu] = useState(false)
  const handleSettingsClick = () => {
    setOpenSettingsMenu(!openSettingsMenu)
  }
  const handleSettingsClose = () => {
    setOpenSettingsMenu(false)
  }
  const handleSundayFirst = () => {
    props.setMondayFirst(false)
    setOpenSettingsMenu(false)
  }
  const handleMondayFirst = () => {
    props.setMondayFirst(true)
    setOpenSettingsMenu(false)
  }

  const openNewPost = () => {
    if(props.todayPost){
      console.log("TODO: tell user they already have a post for that day")
      props.openModalPost(props.todayPost, false)
    } else {
      props.setModalDate(Moment())
      props.setOpenModal(true)
    }
  }

  if(error){
    return <div>{error}</div>
  }
  
  return (
    <div>
      <div className={classes.root} >
        <span className={classes.navbarSide}>
          <Avatar 
            alt='Team Picture'
            variant='square' 
            src={(teamData && teamData.team && teamData.team.profilePictureURL) ? teamData.team.profilePictureURL : ""}
            className={classes.profilePicture}
          />
          <TeamSelectDropdown 
              
              setViewValue={props.setViewValue}
              teamList={(data && data.me && data.me.teamList) ? data.me.teamList: []}
              setSelectedTeamId={props.teamId}
              teamName={(teamData && teamData.team && teamData.team.name) ? teamData.team.name : ''}
              me={(data && data.me) ? data.me : ""}
              user={null}
          />
        </span>
        <div className={classes.spacer}></div>
        <Hidden xsDown>
          <Tabs
            value={props.viewValue}
            className={classes.tabs}
            indicatorColor="primary"
            centered
            onChange={handleChange}
          >
            <Tab label="Calendar" />
            <Tab label="Overview" />
          </Tabs>
        </Hidden>
        <div className={classes.spacer}></div>
        <span className={classes.navbarSide}>
          {props.viewValue === CALENDAR_VIEW_VALUE && 
            <Hidden xsDown>
              <span className={classes.addFab}>
                <Tooltip title="Post Today" enterDelay={400}>
                  <Fab color="primary" aria-label="add" size="small" variant="extended" className={classes.addButton} onClick={openNewPost}>
                    <AddIcon className={classes.addIcon}/>
                    Post
                  </Fab>
                </Tooltip>
              </span>
              </Hidden>}
          {props.viewValue === CALENDAR_VIEW_VALUE && <div className={classes.settings} ref={settingsButtonRef}>
            <Tooltip title="Settings" enterDelay={400}>
              <IconButton 
                aria-label='settings'
                onClick={handleSettingsClick}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-settings"
              className={classes.settingsMenu}
              anchorEl={settingsButtonRef.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openSettingsMenu}
              onClose={handleSettingsClose}
            >
              {/* {!joined && !requestPending && <MenuItem onClick={joinTeam}>Join Team</MenuItem>}
              {joined && !requestPending && <MenuItem onClick={leaveTeam}>Leave Team</MenuItem>}
              {requestPending && <MenuItem>Request Pending</MenuItem>} */}
              <MenuItem onClick={handleSundayFirst}>Sundays First</MenuItem>
              <MenuItem onClick={handleMondayFirst}>Mondays First</MenuItem>
            </Menu>
          </div>}
        </span>
      </div>
      <div>
        <Hidden smUp>
          <Tabs
            value={props.viewValue}
            className={classes.tabs}
            indicatorColor="primary"
            style={{margin: '0 8px'}}
            variant="fullWidth"
            centered
            onChange={handleChange}
          >
            <Tab label="Calendar" />
            <Tab label="Overview" />
          </Tabs>
        </Hidden>
      </div>
    </div>);

}