import React, { useState, useContext  } from 'react';
import { useLazyQuery, useQuery, gql, useMutation, NetworkStatus } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { NotificationTile } from './NotificationTile'
import { NoResults } from './NoResults'
import { Waypoint } from 'react-waypoint';
import { GET_NOTIFICATIONS_QUERY, GET_POST_BY_ID_QUERY } from '../../utils/graphql'
import { NotificationData } from '../../operation-result-types';
import { PostModal } from '../Modal/PostModal';
import { NewActivityModal } from '../Modal/NewActivityModal';
import Moment from 'moment';
import { AuthContext } from '../../auth';


const MARK_ALL_READ_MUTATION = gql`
  mutation markAllRead {
    markAllRead {
      success
    }
  }
`;

var clickedBell: boolean = false

const NOTIFICATION_LIMIT: number = 10
let page: number = 1

type NotificationBellProps = {
  handleNotificationOpen: () => void;
  notificationCount: number,
  openNotification: boolean,
  handleDrawerClose: () => void,
}

var moreDetailPost: any = {}

export const NotificationBell: React.FC<NotificationBellProps> = ({
  handleNotificationOpen,
  notificationCount,
  openNotification,
  handleDrawerClose
}) => {

  const [viewPost, setViewPost] = useState(false);
  const [modalPost, setModalPost] = useState(null)
  const [openModal, setOpenModal] = useState(false);
  const [editPost, setEditPost] = useState(null)
  const modalDate = Moment();


  const { data, loading, fetchMore, networkStatus: notificationNetworkStatus } = useQuery(GET_NOTIFICATIONS_QUERY, {variables: {page: page, limit: NOTIFICATION_LIMIT}, notifyOnNetworkStatusChange: true});

  const [markAllReadMutation] = useMutation(MARK_ALL_READ_MUTATION, {
    update(_) {
      clickedBell = true
    },
    onError(error) {
      console.log(error)
    }
  });

  const [getUserPost, { data: userPostData, loading: userPostLoading, refetch: userPostRefetch, networkStatus }] = useLazyQuery(GET_POST_BY_ID_QUERY, {
    onCompleted: (result) => {
        if (networkStatus === NetworkStatus.refetch){
            setModalPost(result.post)
        } else {
            moreDetailPost[result.post.id] = result.post
            setModalPost(result.post)
            setViewPost(true)
        }
        return 
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => console.log(error)
})

  const openModalPost = (post: any) => {
    // get post data
    getUserPost({
        variables: {id: post.id},
    })
    // TODO I think check if in moreDetailPost, and used the already fetched post
    if(userPostData && userPostData.post && !userPostLoading){
        setModalPost(userPostData.post)
        setViewPost(true)
    }
  }

  const fetchMoreNotifications = () => {
    if(data.getNotifications.paginator.hasNextPage) {
      fetchMore({variables: {page: data.getNotifications.paginator.next, limit: NOTIFICATION_LIMIT}})
    }
  }

  const classes = useStyles();
  const { user } = useContext(AuthContext)

  return (
    <div>
        <div className={classes.root}>
            <IconButton onClick={handleNotificationOpen}>
                <Badge overlap="circle" badgeContent={clickedBell ? 0 : notificationCount} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
        </div>
        {openNotification &&
        <Box className={classes.notificationArea}>
          <List className={classes.results}>
            <Waypoint onEnter={() => markAllReadMutation()}/> 
            { (loading && !data)  ? <CircularProgress className={classes.loadingResults}/> :
            (data && data.getNotifications && data.getNotifications.notifications) && 
            (data.getNotifications.notifications.length >= 1 ?
              data.getNotifications.notifications.map((notification: NotificationData, index: number) => (
                <React.Fragment key={"notification-" + notification.id}>
                  <NotificationTile 
                    //key={"notification-" + notification.id} 
                    notification={notification} 
                     
                    handleDrawerClose={handleDrawerClose} 
                    openModalPost={openModalPost}
                    user={user}
                  />
                  { (index === data.getNotifications.notifications.length - 4 && data.getNotifications.paginator.hasNextPage) && (
                    <Waypoint onEnter={fetchMoreNotifications}/>
                  )}
                </React.Fragment>
              )) :
              <NoResults/>)
            }
            {(notificationNetworkStatus === 3) && <div className={classes.loadingNotifications}><CircularProgress/></div>}
          </List>
        </Box> }
        <NewActivityModal openModal={openModal} handleClose={() => setOpenModal(false)} modalDate={modalDate} editPost={editPost} setEditPost={setEditPost}/>
        <PostModal open={viewPost} loading={userPostLoading && !userPostData} isCommenting={false} handleClose={() => setViewPost(false)} post={modalPost}  openEditPostModal={() => { setOpenModal(true); setViewPost(false);}} setEditPost={setEditPost} userPostRefetch={userPostRefetch}/>
    </div>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 24,
    marginRight: 12
  },  
  notificationArea: {
    borderRadius: 8,
    boxShadow: '0 2px 30px 0 rgba(2,33,77,0.15)',
    width: 512,
    position: 'absolute',
    right: 80,
    top: 56,
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      right: 0, 
    }
  },  
  results: {
    borderRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
    maxHeight: "60vh",
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      minHeight: "100vh",
      height: '100vh',
      maxHeight: "100vh",
      paddingBottom: 56
    }
  },
  loadingResults: {
    margin: 'auto',
    marginTop: 16,
    marginBottom: 16,
    display: 'block',
  },
  loadingNotifications: {
    margin: 'auto',
    marginTop: 16,
    marginBottom: 16,
    display: 'block',
    width: '40px',
  }
}));