import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTIFICATION } from '../../utils/graphql'
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  message: {
    marginBottom: 8,
    display: 'block',
    overflowWrap: 'anywhere'
  },
  responseRequiredMessage: {
    marginBottom: 8,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      width: 214
    },
  },
  resultItem: {
    minHeight: 70,
    paddingTop: 4,
    paddingBottom: 4, 
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: '#fafafa',
    },
  },
  profileClick: {
    cursor: 'pointer',
    userSelect: 'none',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
  profilePicture: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: 16
    },
  },
  buttonRow: {
    marginTop: 8,
    display: 'flex',
  } ,
  actionButton: {
    flex: 1,
    fontSize: '0.8rem'
  },
  clickableItem: {
    cursor: 'pointer',
  },
}));

export const NotificationTile = (props) => {

  const navigate = useNavigate();
  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION, {
    update(store, { data: { updateNotification }}) {
      if(updateNotification.deleted){
        store.evict({id: store.identify({id: updateNotification.id, __typename: 'Notification' })})
        store.gc();
      }
    },
    onError(error) {
      console.log(error)
    }
  });
  
  const accept = () => {
    const notificationInput = {
      input: {
        id: props.notification.id,
        read: true,
        deleted: false,
        accept: true
      }
    }
    updateNotificationMutation({ variables: notificationInput });
    //TODO: update to FOLLOW notification type

  }
  const deny = () => {
    const notificationInput = {
      input: {
        id: props.notification.id,
        read: true,
        deleted: true,
        accept: false
      }
    }
    updateNotificationMutation({ variables: notificationInput });
  }
  const navigateToUserProfile = () => {
    navigate(`/user/${props.notification.sender.id}`);
    if(props.handleDrawerClose){
      props.handleDrawerClose();
    }
  }
  const navigateToTeamProfile = () => {
    navigate(`/team/${props.notification.team.id}`);
    if(props.handleDrawerClose){
      props.handleDrawerClose();
    }  
  }
  const navigateToProfile = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if(props.notification.team && props.notification.type == "TEAM_ACCEPT"){
        navigateToTeamProfile()
    } else {
        navigateToUserProfile()
    }
  }    
  const showPost = () => {
    if(props.notification && props.notification.post && props.notification.post.id){
      props.openModalPost(props.notification.post)
      if(props.handleDrawerClose){
        props.handleDrawerClose()
      }  
    }
  }    

  const typeToUserMessage = function (notification) {
    let message = ''
    switch (notification.type) {
      case 'FOLLOW_REQUEST':
        message = ` wants to follow you`
        break
      case 'FOLLOW':
        message = ` is now following you`
        break
      case 'FOLLOW_ACCEPT':
        message = ` accepted your follow request`
        break
      case 'LIKE_POST':
        message = ` liked your post`
        break
      case 'LIKE_COMMENT':
        message = ` liked your comment`
        break
      case 'COMMENT':
        message = notification.comment ? ` commented: ${notification.comment.note}` : ""
        break
      case 'MENTIONED_POST':
        message = ` tagged you in a post`
        break
      case 'POST_NOTIFICATION':
        message = 'Post Notification, not implemented yet'
        break
      case 'OTHER_COMMENT':
        message = ` commented on a post you commented on`
        break
      case 'TEAM_REQUEST':
        message = ` requested to join your team`
        break
      case 'TEAM_JOINED':
        message = ` joined your team`
        break
      case 'TEAM_ACCEPT':
        message = ` accepted you to their team`
        break
      default:
        message = '';
    }
    // now this part stops the click from propagating

    return message
  }

  const getResultClass = () => {
    var cellClass = `${classes.resultItem}`
    if(props.notification && props.notification.post && props.notification.post.id){
      cellClass = `${cellClass} ${classes.clickableItem}`
    }
    return cellClass
  }

  const clickedCaption = (notification) => {
    let message = ''

    switch (notification.type) {
      case 'LIKE_POST':
      case 'LIKE_COMMENT':
      case 'COMMENT':
      case 'OTHER_COMMENT':
        return showPost
      case 'MENTIONED_POST':
        message = ` tagged you in a post`
        break
      case 'POST_NOTIFICATION':
        message = 'Post Notification, not implemented yet'
        break
      case 'FOLLOW':
      case 'FOLLOW_ACCEPT':
      case 'TEAM_REQUEST':
      case 'TEAM_JOINED':
        return navigateToUserProfile
      case 'TEAM_ACCEPT':
        return navigateToTeamProfile
      case 'FOLLOW_REQUEST':
      default:
        message = "";
    }
    return message
  }

  const getNotificationCaption = (notification) => {
    let message = ''
    switch (notification.type) {
      case 'FOLLOW':
        message = "Check out their profile!"
        break
      case 'FOLLOW_ACCEPT':
        message = "Check out their profile!"
        break
      case 'LIKE_POST':
        message = notification.post ? notification.post.title : ""
        break
      case 'LIKE_COMMENT':
        message = notification.comment ? notification.comment.note : ""
        break
      case 'COMMENT':
        message = notification.post ? notification.post.title : ""
        break
      case 'MENTIONED_POST':
        message = ` tagged you in a post`
        break
      case 'POST_NOTIFICATION':
        message = 'Post Notification, not implemented yet'
        break
      case 'OTHER_COMMENT':
        message = notification.post ? notification.post.title : ""
        break
      case 'TEAM_REQUEST':
        message = notification.team ? notification.team.name : ""
        break
      case 'TEAM_JOINED':
        message = "Check out their profile!"
        break
      case 'TEAM_ACCEPT':
        message = notification.team ? notification.team.name : ""
        break
      case 'FOLLOW_REQUEST':
      default:
        message = '';
    }
    return message
  }
  // TODO: accept/deny follow request button functionality

  const classes = useStyles();
  return (
    <ListItem alignItems="flex-start" className={getResultClass()} style={{backgroundColor: props.notification.read ? '#ffffff' : '#fffafc'}} divider onClick={() => showPost()}> 
        <ListItemAvatar>
          <Avatar 
            alt="Profile Picture" 
            variant= {(props.notification.team && props.notification.type == "TEAM_ACCEPT") ? "square" : "circular"} 
            src={(props.notification.team && props.notification.type == "TEAM_ACCEPT") ? props.notification.team.profilePictureURL : props.notification.sender.profilePictureURL}
            onClick={navigateToProfile}
            className={classes.profilePicture}
           />
        </ListItemAvatar>
        <ListItemText 
          primary={<div style={{fontSize: 14, overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><span onClick={(e) => navigateToProfile(e)} className={classes.profileClick}>
            {(props.notification && props.notification.sender && props.notification.type && <a>{props.notification.sender.first} {props.notification.sender.last}</a>)}
             </span> {typeToUserMessage(props.notification)} </div>}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={props.notification.responseRequired ? classes.responseRequired : classes.message}
                color="textSecondary"
              >
                {props.notification && <span className={classes.profileClick} onClick={clickedCaption(props.notification)}>{getNotificationCaption(props.notification)} </span> }
              </Typography>
              {props.notification.responseRequired && 
                <span className={classes.buttonRow}>
                  <Button className={classes.actionButton} onClick={accept} style={{marginRight: 8}} variant="contained" color="primary">
                      Accept
                  </Button>
                  <Button className={classes.actionButton} onClick={deny} variant="outlined" color="default">
                      Deny
                  </Button>
                </span>

              }
            </React.Fragment>
            
          }
        />
      </ListItem>);
}
